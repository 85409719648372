<template>
    <div>
        <v-row>
            <v-col cols="12" class="titulo-pagamento">
                <p>Documentos</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <InputDocumentos
                    :modelValue="files"
                    @update:modelValue="files = $event"
                />
            </v-col>
        </v-row>
        <v-row class="my-3 flex justify-center">
            <div v-if="documentoCpf">
                <p><strong>Documento de Identificação CNH</strong></p>
                <ul>
                    <li>
                        Caso outro documento de identificação com foto seja
                        enviado, solicitamos que aguarde junto ao Atendente a
                        confirmação da possibilidade de atendimento.
                    </li>
                </ul>
            </div>

            <div v-if="documentoCnpj">
                <p><strong>Documentos necessários para CNPJ</strong></p>
                <ul>
                    <li>
                        Caso seja MEI: Anexar documento de identificação +
                        consulta CEI,
                    </li>
                    <li>
                        Caso possua QSA: Anexar documento de identificação +
                        consulta QSA,
                    </li>
                    <li>
                        Caso não seja mais MEI: Anexar documento de
                        identificação + Alteração Contratual,
                    </li>
                    <li>
                        Caso seja do estado de SP: Anexar documento de
                        identificação + Certidão Simplificada.,
                    </li>
                    <li>
                        Documento de Identificação: CNH (Preferencialmente),
                    </li>
                    <li>
                        Caso outro documento de identificação com foto seja
                        enviado, solicitamos que aguarde junto ao Atendente a
                        confirmação da possibilidade de atendimento.
                    </li>
                </ul>
            </div>
        </v-row>

        <hr />

        <v-row>
            <v-col cols="2" class="d-flex justify-start">
                <v-btn color="blue" dark @click="voltarPasso"> Voltar </v-btn>
            </v-col>

            <v-col cols="2" offset="8" class="d-flex justify-end">
                <v-btn
                    color="green"
                    :dark="!isLoading"
                    :disabled="isLoading"
                    :loading="isLoading"
                    @click="enviarArquivo"
                >
                    Próximo
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { servico } from '@/services/VendasServices'
import InputDocumentos from '../../../../components/Inputs/InputDocumentos.vue'

export default {
    data() {
        return {
            files: [],
            isLoading: false,
        }
    },
    name: 'Documentos',
    components: {
        InputDocumentos,
    },
    props: {
        venda: [Object, Array],
        documentoCpf: Boolean,
        documentoCnpj: Boolean,
    },
    methods: {
        proximoPasso() {
            this.$emit('proximoPasso')
        },
        voltarPasso() {
            this.$emit('voltarPasso')
        },
        notificarFaltaDeDocumento() {
            this.$emit('notificarDocumento')
        },
        async enviarArquivo() {
            if (!this.files) {
                this.notificarFaltaDeDocumento()
                return
            }

            const formData = new FormData()
            formData.append('venda_id', this.venda.id)

            this.files.forEach((doc) => {
                formData.append(`files[]`, doc.file)
            })

            this.isLoading = true
            const response = await servico.uploadDocumentoVendaRapida(formData)
            this.isLoading = false

            if (response.status !== 200) {
                const mensagem =
                    response.data.message || 'Erro ao salvar Documentos'
                this.$notify({
                    type: 'error',
                    title: 'Erro:',
                    text: mensagem,
                })
                return
            }

            this.$notify({
                type: 'success',
                title: 'Sucesso',
                text: 'Tudo certo, arquivos atualizados .',
            })
            this.proximoPasso()
        },
    },
    watch: {
        loader() {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
    },
}
</script>

<style>
.titulo-pagamento {
    font-size: 1.6em;
    border-radius: 7px;
    font-weight: bold;
}

hr {
    border: 0.5px solid;
    border-color: #dedede;
}
</style>
