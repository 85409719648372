import VendaRapida from '@/views/PaginasExternas/views/VendaRapida/VendaRapida'
import PaginaNaoEncontrada from '@/views/PaginasExternas/views/PaginaNaoEncontrada/PaginaNaoEncontrada'
import LinkExpirado from '@/views/PaginasExternas/views/LinkExpirado/LinkExpirado.vue'
import DocumentacaoVenda from '@/views/PaginasExternas/views/DocumentacaoVenda/DocumentacaoVenda.vue'
// import WebPessoaAgente from '../Pessoas/Agente/components/WebPessoaAgente.vue'

export default [
    {
        meta: {
            verificaLogado: false,
            paginaExterna: true,
        },
        path: '/web/pagina-nao-encontrada',
        name: 'PaginaNaoEncontrada',
        component: PaginaNaoEncontrada,
    },
    {
        meta: {
            verificaLogado: false,
            paginaExterna: true,
        },
        path: '/web/link-expirado',
        name: 'LinkExpirado',
        component: LinkExpirado,
    },
    {
        meta: {
            verificaLogado: false,
            paginaExterna: true,
        },
        path: '/web/venda-rapida/:token',
        name: 'WebVendaRapida',
        component: VendaRapida,
    },
    {
        meta: {
            verificaLogado: false,
            paginaExterna: true,
        },
        path: '/web/documentacao-pendente/:id',
        name: 'DocumentacaoVenda',
        component: DocumentacaoVenda,
    },
    // {
    //     meta: {
    //         verificaLogado: false,
    //         paginaExterna: true,
    //     },
    //     path: '/web/agente-registro/:token',
    //     name: 'WebAgenteRegistro',
    //     component: WebPessoaAgente,
    // },
]
