import { render, staticRenderFns } from "./StepperBody.vue?vue&type=template&id=06ae3563&scoped=true"
import script from "./StepperBody.vue?vue&type=script&lang=js"
export * from "./StepperBody.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ae3563",
  null
  
)

export default component.exports