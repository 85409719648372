<template>
  <div>
      <CartaoCredito
          :venda_id="venda_id"
          :id_externo_venda="id_externo_venda"
      />
  </div>
</template>

<script>
import CartaoCredito from '@/components/CartaoCredito.vue'

export default {
  name: 'FormularioPix',
  components: { CartaoCredito },
  props: {
      venda_id: Number,
      id_externo_venda: String,
  },
}
</script>

<style></style>
