<template>
    <div style="height: 100%">
        <div v-show="!cobranca_gerada" style="height: 100%">
            <v-row
                class="d-flex justify-center align-center"
                style="height: 100%"
            >
                <v-col cols="12" class="d-flex justify-center align-center">
                    <v-btn
                        class="mt-5"
                        color="blue"
                        @click="gerarChavePix()"
                        dark
                        >Gerar Chave Pix</v-btn
                    >
                </v-col>
            </v-row>
        </div>

        <div v-show="cobranca_gerada" style="height: 100%">
            <div v-if="loading_key" style="height: 100%">
                <v-row style="height: 100%">
                    <v-col cols="12" class="d-flex align-center justify-center">
                        <v-progress-circular
                            size="50"
                            color="cor-sistema"
                            indeterminate
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </div>

            <div v-else style="height: 100%">
                <v-row style="height: 80%">
                    <v-col cols="12" class="d-flex align-center justify-center">
                        <QrcodeVue :value="key" :size="250"></QrcodeVue>
                    </v-col>
                </v-row>

                <v-row style="height: 20%">
                    <v-col cols="12" class="d-flex align-center justify-center">
                        <v-btn
                            color="blue"
                            @click="copiarParaAreaTransferencia()"
                            dark
                            >Copiar Chave</v-btn
                        >
                    </v-col>
                </v-row>
            </div>
        </div>

        <input type="hidden" id="input-copy" :value="key" />
        <v-snackbar v-model="snackbar" timeout="2000" color="blue" text>
            <div class="d-flex justify-center">
                <svg
                    width="19"
                    viewBox="0 0 64 48"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M25.4793 50.8918L25.1713 50.367C20.4667 42.3513 7.96483 25.3407 7.83855 25.1698L7.6582 24.9248L11.9175 20.7154L25.4002 30.13C33.8893 19.1142 41.809 11.548 46.975 7.12969C52.6262 2.29646 56.3047 0.0713948 56.3418 0.0500728L56.4255 0H63.6505L62.9603 0.614626C45.2109 16.424 25.9723 50.0254 25.7807 50.363L25.4793 50.8918Z"
                        fill="#0178ae"
                    />
                </svg>
                <span class="ml-3">Copiado para Area de Transferência!</span>
            </div>
        </v-snackbar>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { services } from '@/services/PaginasExternasServices'

export default {
    name: 'FormularioPix',
    components: { QrcodeVue },
    mixins: { services },
    props: {
        venda_id: Number,
    },
    data: () => ({
        key: '',
        snackbar: false,
        loading_key: false,
        cobranca_gerada: false,
    }),
    watch: {},
    methods: {
        copiarParaAreaTransferencia() {
            var copy = document.querySelector('#input-copy')
            copy.setAttribute('value', this.key)
            copy.setAttribute('type', 'text')
            copy.select()
            document.execCommand('copy')

            this.snackbar = true
            copy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        gerarChavePix() {
            this.loading_key = true
            services
                .gerarChavePix({ venda_id: this.venda_id })
                .then((resposta) => {
                    this.loading_key = false
                    this.cobranca_gerada = true
                    this.key = resposta.data.key
                })
                .catch(() => {
                    this.loading_key = false
                    this.cobranca_gerada = false
                })
        },
    },
}
</script>

<style></style>
