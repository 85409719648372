<template>
    <div>
        <p>Nível da Senha: {{ nivel_senha.texto }}</p>
        <v-progress-linear
            :value="nivel_senha.valor"
            :color="nivel_senha.cor"
        ></v-progress-linear>
        <div class="msg-box-senha">
            <p
                class="msg-senha"
                v-for="(msg, index) in nivel_senha.msg"
                :key="index"
            >
                * {{ msg }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ValidadorNivelSenha',
    props: {
        senha: {
            type: String,
            required: true,
        },
        confirmar_senha: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            nivel_senha: {
                valor: 0,
                cor: 'red',
                texto: 'Fraca',
                msg: [],
            },
        }
    },
    watch: {
        senha() {
            this.validarNivel()
        },
    },
    methods: {
        validarNivel() {
            const regexNum = /[0-9]/
            const regexMai = /[A-Z]/
            const regexChar = /[!@#$%^&*]/
            this.nivel_senha.valor = 0
            this.nivel_senha.cor = 'red'
            this.nivel_senha.msg = []
            this.autorizar(false)

            var valor_por_validador = 100 / (this.confirmar_senha ? 5 : 4)

            if (this.senha.length < 8) {
                this.nivel_senha.msg.push('Limite minimo de 8 Caracteres.')
            } else {
                this.nivel_senha.valor =
                    this.nivel_senha.valor + valor_por_validador
            }

            if (!regexNum.test(this.senha)) {
                this.nivel_senha.msg.push(
                    'Deve conter ao menos um Número na senha.'
                )
            } else {
                this.nivel_senha.valor =
                    this.nivel_senha.valor + valor_por_validador
            }

            if (!regexMai.test(this.senha)) {
                this.nivel_senha.msg.push(
                    'Deve conter ao menos uma letra Maiuscula na senha.'
                )
            } else {
                this.nivel_senha.valor =
                    this.nivel_senha.valor + valor_por_validador
            }

            if (!regexChar.test(this.senha)) {
                this.nivel_senha.msg.push(
                    'Deve conter ao menos um Caracter Especial na senha. (Ex: !, @, #, $, &...)'
                )
            } else {
                this.nivel_senha.valor =
                    this.nivel_senha.valor + valor_por_validador
            }

            if (this.confirmar_senha) {
                if (this.senha !== this.confirmar_senha) {
                    this.nivel_senha.msg.push(
                        'A Senha e Confirmar Senha devem ser Iguais'
                    )
                } else {
                    this.nivel_senha.valor =
                        this.nivel_senha.valor + valor_por_validador
                }
            }

            if (this.nivel_senha.valor <= 50) {
                this.nivel_senha.cor = 'red'
                this.nivel_senha.texto = 'Muito Fraca'
            }
            if (this.nivel_senha.valor > 50 && this.nivel_senha.valor <= 75) {
                this.nivel_senha.cor = 'orange'
                this.nivel_senha.texto = 'Fraca'
            }
            if (this.nivel_senha.valor === 100) {
                this.nivel_senha.cor = 'green'
                this.nivel_senha.texto = 'Forte'
                this.autorizar(true)
            }
            if (this.nivel_senha.valor > 100) {
                this.nivel_senha.cor = 'lime'
                this.nivel_senha.texto = 'Muito Forte'
                this.autorizar(true)
            }
            this.$emit('cor_nivel', this.nivel_senha.cor)
        },
        autorizar(resposta) {
            this.$emit('senha_aprovada', resposta)
        },
    },
}
</script>

<style scoped>
.msg-box-senha {
    padding: 10px;
}

.msg-senha {
    margin: 0px -5px;
    font-weight: 400;
}

.msg-senha-igual {
    margin: 0;
    color: red;
    margin-top: -20px;
    font-weight: 451;
}

.progresso-senha {
    padding: 0px 30px;
}
</style>
