<script>
export default {
    name: 'InputDocumentosAlt',
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            documents: [...this.modelValue],
        }
    },
    watch: {
        documents: {
            handler() {
                this.$emit('update:modelValue', this.documents)
            },
            deep: true,
        },
    },
    methods: {
        handleFileUpload(event) {
            const fileInput = event.target
            const files = event.target.files

            if (files && files.length > 0) {
                Array.from(files).forEach((file) => {
                    const allowedTypes = [
                        'application/pdf',
                        'image/jpeg',
                        'image/png',
                    ]
                    if (!allowedTypes.includes(file.type)) {
                        this.message(
                            'Por favor, selecione apenas imagens ou arquivos PDF.'
                        )
                        fileInput.value = ''
                        return
                    }

                    this.documents.push({
                        name: file.name,
                        file: file,
                    })

                    fileInput.value = ''
                })
            }
        },
        triggerFileInput() {
            const fileInput = document.getElementById(`file-input`)
            if (fileInput) {
                fileInput.click()
            }
        },
        removeDocument(index) {
            this.documents.splice(index, 1)
        },
    },
}
</script>

<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="8" class="d-flex document-field">
                <div style="min-width: 200px; min-height: 200px">
                    <input
                        multiple
                        type="file"
                        id="file-input"
                        class="file-input"
                        accept="image/*,.pdf"
                        @change="handleFileUpload"
                    />
                    <div
                        v-if="documents && documents.length > 0"
                        class="document-list"
                    >
                        <ul>
                            <li
                                v-for="(document, index) in documents"
                                :key="index"
                            >
                                <span>{{ document.name }}</span>
                                <button
                                    class="remove-btn"
                                    @click.prevent="removeDocument(index)"
                                >
                                    <v-icon small color="red">mdi-close</v-icon>
                                </button>
                            </li>
                            <li class="d-flex justify-center align-center">
                                <svg
                                    @click="triggerFileInput"
                                    style="cursor: pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-plus"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path d="M12 5l0 14" />
                                    <path d="M5 12l14 0" />
                                </svg>
                            </li>
                        </ul>
                    </div>
                    <div
                        @click="triggerFileInput"
                        v-else
                        class="d-flex flex-column align-center justify-center upload-btn"
                    >
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 47 47"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.9068 9.91771C13.8538 9.28498 14.9671 8.94727 16.106 8.94727C16.8371 8.94727 17.4299 9.54002 17.4299 10.2712C17.4299 11.0024 16.8371 11.5952 16.106 11.5952C15.4908 11.5952 14.8894 11.7776 14.3779 12.1194C13.8664 12.4611 13.4677 12.9469 13.2323 13.5153C12.9969 14.0836 12.9353 14.709 13.0553 15.3124C13.1753 15.9157 13.4716 16.4699 13.9066 16.9049C14.3416 17.3399 14.8958 17.6362 15.4991 17.7562C16.1025 17.8762 16.7279 17.8146 17.2962 17.5792C17.8646 17.3438 18.3504 16.9451 18.6922 16.4336C19.0339 15.9221 19.2163 15.3207 19.2163 14.7056C19.2163 13.9744 19.8091 13.3816 20.5403 13.3816C21.2715 13.3816 21.8642 13.9744 21.8642 14.7056C21.8642 15.8444 21.5265 16.9577 20.8938 17.9047C20.2611 18.8516 19.3617 19.5897 18.3096 20.0255C17.2574 20.4614 16.0996 20.5754 14.9826 20.3532C13.8656 20.131 12.8395 19.5826 12.0342 18.7773C11.2289 17.972 10.6805 16.9459 10.4583 15.8289C10.2361 14.7119 10.3502 13.5541 10.786 12.502C11.2218 11.4498 11.9599 10.5504 12.9068 9.91771Z"
                                fill="#8D98AA"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M44.8708 16.625C45.5915 16.625 46.1757 17.2214 46.1757 17.9571V29.1108C46.1757 34.8166 45.0721 39.1573 42.2651 42.0388C39.4562 44.9221 35.2231 46.0563 29.6609 46.0563H16.6242C11.0637 46.0563 6.83069 44.9225 4.02147 42.0395C1.21398 39.1583 0.109375 34.8177 0.109375 29.1108V24.6262C0.109375 23.8905 0.693597 23.2941 1.41427 23.2941C2.13495 23.2941 2.71917 23.8905 2.71917 24.6262V29.1108C2.71917 34.5576 3.7894 38.0237 5.87178 40.1608C7.95245 42.2961 11.3243 43.3922 16.6242 43.3922H29.6609C34.9625 43.3922 38.3343 42.2956 40.4145 40.1602C42.4965 38.023 43.5659 34.5569 43.5659 29.1108V17.9571C43.5659 17.2214 44.1502 16.625 44.8708 16.625Z"
                                fill="#8D98AA"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.07578 3.92542C6.92236 1.10124 11.2117 -0.00976562 16.8502 -0.00976562H25.6654C26.3922 -0.00976562 26.9814 0.574775 26.9814 1.29584C26.9814 2.01691 26.3922 2.60145 25.6654 2.60145H16.8502C11.4714 2.60145 8.04838 3.6769 5.93684 5.77183C3.82529 7.86675 2.74131 11.2629 2.74131 16.5993C2.74131 17.3204 2.15213 17.9049 1.42534 17.9049C0.698553 17.9049 0.109375 17.3204 0.109375 16.5993C0.109375 11.0052 1.2292 6.74959 4.07578 3.92542Z"
                                fill="#8D98AA"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M29.5391 7.66829C29.5391 6.96158 30.1523 6.38867 30.9088 6.38867H43.5248C44.2813 6.38867 44.8945 6.96158 44.8945 7.66829C44.8945 8.37501 44.2813 8.94791 43.5248 8.94791H30.9088C30.1523 8.94791 29.5391 8.37501 29.5391 7.66829Z"
                                fill="#8D98AA"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M37.2171 -0.00976562C37.9238 -0.00976562 38.4967 0.603478 38.4967 1.35995V13.976C38.4967 14.7324 37.9238 15.3457 37.2171 15.3457C36.5104 15.3457 35.9375 14.7324 35.9375 13.976V1.35995C35.9375 0.603478 36.5104 -0.00976562 37.2171 -0.00976562Z"
                                fill="#8D98AA"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M36.0039 25.1671L26.9476 33.0066L26.9236 33.0269C25.8225 33.9347 24.4411 34.431 23.0153 34.431C21.5896 34.431 20.2081 33.9347 19.1071 33.0269C19.0952 33.0171 19.0835 33.0071 19.072 32.997L18.3729 32.378C17.7875 31.9056 17.0684 31.6296 16.3175 31.5895C15.5619 31.5491 14.8131 31.7496 14.1783 32.162L3.43445 39.4338C2.83474 39.8397 2.02024 39.6815 1.61521 39.0805C1.21018 38.4795 1.368 37.6633 1.9677 37.2574L12.7385 29.9674C13.8407 29.247 15.143 28.8968 16.4571 28.967C17.7711 29.0372 19.0288 29.5245 20.0483 30.3583C20.0615 30.3691 20.0745 30.3802 20.0873 30.3915L20.7885 31.0123C21.4178 31.5248 22.2041 31.8048 23.0153 31.8048C23.8288 31.8048 24.6173 31.5232 25.2474 31.0081L34.3037 23.1685L34.3277 23.1482C35.4287 22.2404 36.8102 21.7441 38.236 21.7441C39.6617 21.7441 41.0432 22.2404 42.1442 23.1482L42.1683 23.1686L45.7236 26.2465C46.2712 26.7206 46.3317 27.5498 45.8586 28.0986C45.3855 28.6474 44.5581 28.7079 44.0104 28.2338L40.468 25.167C39.8378 24.6519 39.0495 24.3704 38.236 24.3704C37.4224 24.3704 36.634 24.6519 36.0039 25.1671Z"
                                fill="#8D98AA"
                            />
                        </svg>
                        <p style="font-size: 13px">Clique para anexar</p>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<style>
.document-field {
    padding-bottom: 0 !important;
}

.file-input {
    display: none;
}

.document-list {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    max-height: 150px;
    overflow-y: auto;
    flex: 1;
    margin-right: 16px;
    width: 200px;
    height: 170px;
}

.document-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.document-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-bottom: 1px solid #eee;
}

.document-list li:last-child {
    border-bottom: none;
}

.remove-btn {
    color: red;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: bold;
}

.remove-btn:hover {
    text-decoration: underline;
}

.upload-btn {
    min-height: 170px;
    cursor: pointer;
    background-color: #edf2f6;
    border-radius: 23px;
    border: 1px dashed #4c535f;
    padding-block: 10px;
    gap: 20px;
    color: #4c535f;
}
</style>
