export const ResponseHelper = {
    mensagemErro(response) {
        if (response.errors) {
            return response.errors[Object.keys(response.errors)[0][0]]
        }

        if (response.message) {
            return response.message
        }

        return ''
    },
}
