import icon from '@/assets/imgs/CertControlIcon.png'
export default {
    install(Vue) {
        Vue.prototype.$notificar = function (title, message, route) {
            if (!('Notification' in window)) {
                alert('Este navegador não suporta notificações de desktop')
            } else if (Notification.permission === 'granted') {
                this.$mostrarNotificacao(title, message, route)
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        this.$mostrarNotificacao(title, message, route)
                    }
                })
            }
        }

        Vue.prototype.$mostrarNotificacao = function (title, message, route) {
            const isFirefox = typeof InstallTrigger !== 'undefined'
            const notificacao = isFirefox
                ? new Notification(title, { body: message + `\n\n${icon}` }) // Adiciona o ícone ao corpo
                : new Notification(title, { body: message, icon: icon })

            notificacao.onclick = function () {
                if (route != null) {
                    this.$router.go(route)
                }
            }
        }
    },
}
