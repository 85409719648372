var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-img',{staticClass:"my-2",attrs:{"src":_vm.dados.personalizacao.link_logo,"max-width":"100%","max-height":"75","contain":""}})],1)],1),(_vm.dados.venda_rapida.titulo)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex align-center justify-center",staticStyle:{"background":"rgba(0, 190, 56, 0.34)","border-radius":"7px"},attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"1.7em","font-weight":"500"}},[_vm._v(_vm._s(_vm.dados.venda_rapida.titulo))])])],1):_vm._e(),_c('div',[_c('v-row',{attrs:{"id":"corpo-pagina"}},[_c('v-col',[_c('Stepper',[_c('StepperHeader',{attrs:{"itens":_vm.itens,"current_step":_vm.step}}),_c('StepperBody',[_c('StepperBodyItem',{attrs:{"step":1,"current_step":_vm.step}},[_c('FormularioCompra',{attrs:{"token":_vm.dados.venda_rapida.token,"venda_id":_vm.dados.venda_rapida.venda_id,"tabela_preco_id":_vm.dados.venda_rapida
                                                .prod_tabelas_id,"produtos":_vm.dados.produtos,"solicitacao_soluti":_vm.dados.pedido_soluti?.solicitacao,"dados_certificado":_vm.dados.dados_certificado,"timestamp-token":_vm.timestampToken},on:{"setNovaVenda":function($event){_vm.dados.venda_rapida.venda_id =
                                                $event},"proximoPasso":function($event){_vm.step = 2}}})],1),_c('StepperBodyItem',{attrs:{"step":2,"current_step":_vm.step}},[_c('Pagamento',{staticClass:"px-15",attrs:{"pagamento_obrigatorio":_vm.dados.venda_rapida
                                                .pagamento_obrigatorio,"venda":_vm.dados.venda,"venda_item":_vm.dados.venda_item,"dados_certificado":_vm.dados.dados_certificado,"id_externo_venda":_vm.dados.id_externo_venda},on:{"pagamentoEfetuado":function($event){_vm.dados.venda.venda_status_id =
                                                $event},"proximoPasso":function($event){_vm.step = 3},"voltarPasso":function($event){_vm.step = 1}}})],1),_c('StepperBodyItem',{attrs:{"step":3,"current_step":_vm.step}},[_c('Documentos',{attrs:{"venda":_vm.dados.venda,"documentoCnpj":_vm.documentoCnpj,"documentoCpf":_vm.documentoCpf},on:{"proximoPasso":function($event){_vm.step = 4},"voltarPasso":function($event){_vm.step = 2},"notificarDocumento":function($event){_vm.dialog = true}}})],1),_c('StepperBodyItem',{attrs:{"step":4,"current_step":_vm.step}},[_c('Agendamento',{staticClass:"px-15",attrs:{"venda_id":_vm.dados.venda_rapida.venda_id,"data_hora_agendada":_vm.dados.agendamento
                                                ?.data_hora_agendada,"reagendar":_vm.reagendar},on:{"agendamentoEfetuado":function($event){_vm.dados.agendamento.data_hora_agendada =
                                                $event},"proximoPasso":function($event){_vm.step = 5
                                            _vm.loadPagina()},"voltarPasso":function($event){_vm.step = 3}}})],1),_c('StepperBodyItem',{attrs:{"step":5,"current_step":_vm.step}},[_c('AgendamentoConfirmado',{attrs:{"venda_id":_vm.dados.venda_rapida.venda_id,"cliente":_vm.dados.dados_certificado
                                                ?.nome_razao,"cpf_cnpj":_vm.dados.dados_certificado
                                                ?.cpf_cnpj,"produto":_vm.dados.venda_item?.nome_produto,"data_hora_agendada":_vm.dados.agendamento
                                                ?.data_hora_agendada,"link_sala":_vm.dados.agendamento?.link_cliente,"url_logo":_vm.dados.personalizacao?.link_logo},on:{"reagendar":_vm.reagendamento}})],1)],1)],1)],1)],1)],1)],1),_c('v-col',[_c('v-col',[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" ATENÇÃO ")]),_c('v-card-text',[_vm._v("Você ainda não anexou o documento, por favor anexe e tente novamente. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }