<script>
export default {
    name: 'InputDocumentos',
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            documents: [...this.modelValue],
        }
    },
    watch: {
        documents: {
            handler() {
                this.$emit('update:modelValue', this.documents)
            },
            deep: true,
        },
    },
    methods: {
        handleFileUpload(event) {
            const fileInput = event.target
            const file = event.target.files[0]

            if (file) {
                const allowedTypes = [
                    'application/pdf',
                    'image/jpeg',
                    'image/png',
                ]
                if (!allowedTypes.includes(file.type)) {
                    this.message(
                        'Por favor, selecione apenas imagens ou arquivos PDF.'
                    )
                    fileInput.value = ''
                    return
                }

                this.documents.push({
                    name: file.name,
                    file: file,
                })

                fileInput.value = ''
            }
        },
        triggerFileInput() {
            const fileInput = document.getElementById(`file-input`)
            if (fileInput) {
                fileInput.click()
            }
        },
        removeDocument(index) {
            this.documents.splice(index, 1)
        },
    },
}
</script>

<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="8" class="d-flex document-field">
                <div class="document-list">
                    <ul>
                        <li v-for="(document, index) in documents" :key="index">
                            <span>{{ document.name }}</span>
                            <button
                                class="remove-btn"
                                @click="removeDocument(index)"
                            >
                                <v-icon small color="red">mdi-close</v-icon>
                            </button>
                        </li>
                    </ul>
                </div>

                <div class="d-flex align-center attach-container">
                    <v-btn
                        color="cor-sistema--btn"
                        @click="triggerFileInput"
                        class="attach-btn"
                    >
                        Anexar documento
                        <v-icon right>mdi mdi-paperclip</v-icon>
                    </v-btn>
                    <input
                        type="file"
                        id="file-input"
                        class="file-input"
                        accept="image/*,.pdf"
                        @change="handleFileUpload"
                    />
                </div>
            </v-col>
            <v-col cols="12" md="8" class="hint-field">
                <small class="text-muted">
                    * Ao clicar em "ANEXAR DOCUMENTO" selecione o arquivo
                    desejado. * Os documentos anexados devem estar no formato
                    PDF ou imagem. * O tamanho maximo não pode ser maior que
                    20Mb.
                </small>
            </v-col>
        </v-row>
    </div>
</template>

<style>
.hint-field {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    white-space: pre-line;
    color: #52525b;
}

.document-field {
    padding-bottom: 0 !important;
}

.file-input {
    display: none;
}

.document-list {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    max-height: 150px;
    overflow-y: auto;
    flex: 1;
    margin-right: 16px;
}

.document-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.document-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-bottom: 1px solid #eee;
}

.document-list li:last-child {
    border-bottom: none;
}

.remove-btn {
    color: red;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: bold;
}

.remove-btn:hover {
    text-decoration: underline;
}

.attach-container {
    display: flex;
    align-items: flex-end;
}

.attach-btn {
    font-size: 14px;
    margin-left: 8px;
}
</style>
