import { wsExternal } from './wsExternal'

export const wsExtSrv = {
    viaCep: (cep) => {
        var url = `https://viacep.com.br/ws/${cep}/json`
        return wsExternal.get(url)
    },
    getBancos: () => {
        var url = `https://brasilapi.com.br/api/banks/v1`
        return wsExternal.get(url)
    },
    buscaPessoaSCC: (strbusca) => {
        let url =
            window.location.protocol +
            `//crm.autenticacertificado.com.br/api/contaspagar`
        let dados = { strbusca: strbusca }
        return wsExternal.post(url, dados, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    validaVideoconf: (requisicao) => {
        var url =
            'https://crm.autenticacertificado.com.br/api/atendevideoconf/' +
            requisicao
        return wsExternal.get(url)
    },
}
