import { http } from './config'

export const helperServices = {
    pessoasTipos: () => {
        var uri = `/helpers/perfil`
        return http.get(uri)
    },
    buscaRepresentantes: () => {
        var uri = `/helpers/repres`
        return http.get(uri)
    },
    buscarEmpresas: () => {
        return http.get('/helpers/empresas')
    },
    buscaTabMaster: (id = 0) => {
        var uri = `/helpers/tabmaster/${id}`
        return http.get(uri)
    },
    buscaMenus: () => {
        var uri = '/helpers/menus'
        return http.get(uri)
    },
    buscaDocumentos: (venda_id) => {
        var uri = '/helpers/documentos/' + venda_id
        return http.get(uri)
    },
    downloadDocumento: (form) => {
        var uri = '/helpers/documento/download'
        return http.post(uri, form)
    },
    buscaTabTipos: () => {
        var uri = '/helpers/tipotabs'
        return http.get(uri)
    },
    buscaDadosFaturamento: (id) => {
        var uri = `/helpers/dados_faturamento/${id}`
        return http.get(uri)
    },
    buscaDadosCertificado: (id) => {
        var uri = `/helpers/dados_certificado/${id}`
        return http.get(uri)
    },
    gerUrlSolicitacao(venda_id) {
        var uri = `/helpers/url-soluti/${venda_id}`
        return http.get(uri)
    },
    consultaRegistroCpfCnpj: (cpf_cnpj) => {
        var uri = '/helpers/consulta-registro-cpf-cnpj/' + cpf_cnpj
        return http.get(uri)
    },
}
