export default {
    name: 'recaptcha',

    data: () => ({
        recaptchaLoaded: false,
        recaptchaInstance: null,
        siteKey: process.env.VUE_APP_GOOGLE_KEY,
    }),

    beforeMount() {
        if (!document.querySelector('script[src*="recaptcha"]')) {
            this.loadRecaptchaScript()
        } else {
            this.recaptchaLoaded = true
            this.initializeRecaptcha()
        }
    },

    beforeDestroy() {
        this.recaptchaInstance = null
        this.recaptchaLoaded = false
    },

    methods: {
        loadRecaptchaScript() {
            return new Promise((resolve, reject) => {
                try {
                    const script = document.createElement('script')
                    script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`

                    script.onload = () => {
                        this.recaptchaLoaded = true
                        this.initializeRecaptcha()
                        resolve()
                    }

                    script.onerror = (error) => {
                        reject(error)
                    }

                    document.head.appendChild(script)
                } catch (error) {
                    reject(error)
                }
            })
        },

        initializeRecaptcha() {
            return new Promise((resolve) => {
                if (!window.grecaptcha) {
                    setTimeout(
                        () => this.initializeRecaptcha().then(resolve),
                        100
                    )
                    return
                }

                window.grecaptcha.ready(() => {
                    this.recaptchaInstance = window.grecaptcha
                    resolve()
                })
            })
        },

        async executeRecaptcha(action = 'public') {
            if (!this.recaptchaLoaded || !this.recaptchaInstance) {
                await this.initializeRecaptcha()
            }

            try {
                return await this.recaptchaInstance.execute(this.siteKey, {
                    action,
                })
            } catch (error) {
                return null
            }
        },
    },
}
