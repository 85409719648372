<template>
    <div>
        <v-row justify="center">
            <v-col cols="9">
                <v-row style="height: 100%">
                    <v-col cols="12" md="6">
                        <v-row>
                            <v-col
                                cols="12"
                                :align="
                                    $vuetify.breakpoint.smAndDown
                                        ? 'center'
                                        : ''
                                "
                            >
                                <span
                                    class="titulo-pagina green--text font-weight-bold"
                                    >Agendamento Confirmado!</span
                                ><br />
                                <span style="font-size: 1.2em"
                                    >Sua videoconferência foi agendada para
                                    <br />
                                    Dia
                                </span>
                                <span style="font-size: 1.2em"
                                    ><strong>{{
                                        formatDate(data_hora_agendada)
                                    }}</strong></span
                                >
                            </v-col>

                            <v-col
                                cols="12"
                                :align="
                                    $vuetify.breakpoint.smAndDown
                                        ? 'center'
                                        : ''
                                "
                            >
                                <span
                                    class="titulo-pagina green--text font-weight-bold"
                                    >Detalhes do Pedido</span
                                ><br />
                                <span style="font-size: 1.2em"
                                    >Nome: <strong>{{ cliente }}</strong></span
                                ><br />
                                <span style="font-size: 1.2em"
                                    >CPF/CNPJ:
                                    <strong>{{ cpf_cnpj }}</strong></span
                                ><br />
                                <span style="font-size: 1.2em"
                                    >Tipo Certificado:
                                    <strong>{{ produto }}</strong></span
                                ><br />
                                <span style="font-size: 1.2em"
                                    >Nº Pedido:
                                    <strong>{{ venda_id }}</strong></span
                                ><br />
                                <a :href="link_sala" target="_blank">
                                    <h3 class="font-weight-bold mt-6">
                                        <v-icon color="blue">mdi-share</v-icon>
                                        Sala Videoconferência: Clique aqui
                                    </h3>
                                </a>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col
                        cols="6"
                        v-show="$vuetify.breakpoint.mdAndUp"
                        style="height: 100%"
                    >
                        <div
                            style="height: 100%"
                            class="d-flex justify-center align-center"
                        >
                            <v-img :src="url_logo" max-width="350"></v-img>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row justify="center" class="my-5">
            <v-col cols="12" class="d-flex justify-center align-center mb-n5">
                Deseja reagendar sua Videoconferência ?
            </v-col>
            <v-col cols="12" class="d-flex justify-center align-center">
                <v-btn
                    small
                    color="green"
                    dark
                    @click="$emit('reagendar', true)"
                >
                    Reagendar
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { services } from '@/services/PaginasExternasServices'

export default {
    name: 'AgendamentoConfirmado',
    mixins: { services },
    props: {
        venda_id: [Number, String],
        cliente: String,
        cpf_cnpj: String,
        produto: String,
        data_hora_agendada: String,
        link_sala: String,
        url_logo: String,
    },
    methods: {
        formatDate(date) {
            if (date == null || date.length < 8) return date
            var [year, month, day] = date.split('-')
            var [iday, time] = day.split(' ')
            var [hour, min] = time.split(':')
            return `${iday}/${month}/${year} as ${hour}:${min}`
        },
    },
}
</script>

<style scoped></style>
