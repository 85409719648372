import axios from 'axios'

export const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
})

export const http2 = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    validateStatus: () => true,
})

http2.putWithForm = (url, data, config = {}) => {
    return http2.post(url + '?_method=PUT', data, config)
}

http2.interceptors.request.use(
    (config) => {
        if (!localStorage.getItem('token')) {
            return config
        }
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

http2.interceptors.response.use(
    (resp) => {
        return resp
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            if (!['/login', '/loginHash'].includes(error.response.config.url)) {
                window.location.href = '/home'
                localStorage.clear()
            }
        }
        return Promise.reject(error)
    }
)

// Interceptor request
http.interceptors.request.use(
    (config) => {
        if (!localStorage.getItem('token')) {
            return config
        }
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

// Interceptor response
http.interceptors.response.use(
    (resp) => {
        return resp
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            if (!['/login', '/loginHash'].includes(error.response.config.url)) {
                window.location.href = '/home'
                localStorage.clear()
            }
        }
        return Promise.reject(error)
    }
)

export const multipart = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        ContentType: 'multipart/form-data',
    },
})
// Interceptor request`
multipart.interceptors.request.use(
    (config) => {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
        var formData = new FormData()
        for (var key in config.data) {
            formData.append(
                key,
                config.data[key] == null ? '' : config.data[key]
            )
        }
        config.data = formData
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

// Interceptor response
multipart.interceptors.response.use(
    (resp) => {
        return resp
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            if (!['/login', '/loginHash'].includes(error.response.config.url)) {
                window.location.href = '/home'
                localStorage.clear()
            }
        }

        return Promise.reject(error)
    }
)
