<template>
    <div class="ma-10">
        <hr class="divider" />
        <v-row justify="center" style="position: relative">
            <v-col
                v-for="(item, key) in itens"
                :key="key"
                :align="alignIcons(key, itens)"
            >
                <StepperHeaderItem
                    :step="item.step"
                    :current_step="current_step"
                    :title="item.title"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import StepperHeaderItem from './StepperHeaderItem.vue'

export default {
    name: 'StepperHeader',
    components: { StepperHeaderItem },
    props: {
        current_step: Number,
        itens: {
            type: Array,
            required: true,
        },
    },
    methods: {
        alignIcons(index, itens) {
            if (index === 0) return 'left'
            if (index > 0 && index < itens.length - 1) return 'center'
            if (index === itens.length - 1) return 'right'
        },
    },
}
</script>
<style scoped>
.divider {
    margin: 20px 0px -35px 0px;
    width: 95%;
    border: solid #dedede 1.5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
</style>
