export default {
    state: {
        loader: {
            status: false,
            loaderTime: 1000,
            color: 'lime darken-2',
        },
    },
    getters: {
        getStatus(state) {
            return state.loader.status
        },
    },
    mutations: {
        setStatus(state, value) {
            state.loader.status = value
        },
    },
}
