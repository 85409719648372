export const LocalStorageHelper = {
    get(key) {
        try {
            const itemString = localStorage.getItem(key)

            if (!itemString) {
                return null
            }
            const item = JSON.parse(decodeURIComponent(atob(itemString)))

            const now = new Date()

            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return null
            }

            return item.value
        } catch (e) {
            return null
        }
    },

    set(key, value, hours = null) {
        const now = new Date()

        if (!hours) {
            hours = 24 - now.getHours()
        }

        const expiration = now.getTime() + hours * 60 * 60 * 1000

        const item = {
            value: value,
            expiry: expiration,
        }

        localStorage.setItem(
            key,
            btoa(encodeURIComponent(JSON.stringify(item)))
        )
    },

    setInDays(key, value, days = 7) {
        const hours = days * 24

        LocalStorageHelper.set(key, value, hours)
    },

    reset() {
        localStorage.clear()
    },
}
