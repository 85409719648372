import { http } from './config'

export const servico = {
    buscaTabela: (values) => {
        return http.post('/vendas/tabela', values)
    },
    buscaTabByPessoaId: (id) => {
        let uri = `/tabelas/pessoa/${id}`
        return http.get(uri)
    },
    comissionados: () => {
        return http.post('/vendas/comissionados')
    },
    finalizaVenda: (values) => {
        return http.post('/vendas', values)
    },
    uploadDocumentoMovimentacao: (values) => {
        return http.post('/movimentacao/documento', values, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            validateStatus: () => true,
        })
    },
    uploadDocumentoVendaRapida: (values) => {
        return http.post('/venda-rapida/documento', values, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            validateStatus: () => true,
        })
    },
    listaVendas: (values) => {
        return http.post('/vendas/gridvendas', values)
    },
    dadosgrafico: () => {
        return http.get('/vendas/dadosgrafico')
    },
    buscaCadastro: (doc) => {
        let uri = `/vendas/doc/${doc}`
        return http.get(uri)
    },

    /** funções da grid de vendas */
    regBoleto: (id) => {
        let uri = `boleto/registro/${id}`
        return http.get(uri)
    },
    baixaPgto: (dados) => {
        return http.post('/vendas/baixapgto', dados)
    },

    getLink: (id) => {
        return http.get('/vendas/link/' + id)
    },

    sendLink: (values) => {
        return http.post('/vendas/sendlink', values)
    },

    getDetalhe: (id) => {
        return http.get(`/vendas/detalhes/${id}`)
    },

    getDadosVenda: (id) => {
        return http.get(`/vendas/dados/${id}`)
    },

    updateDadosCertificado(id, dados) {
        return http.put(`/vendas/dados-certificado/${id}`, dados)
    },

    cancelarVenda(id, dados) {
        return http.post(`/vendas/cancelar/${id}`, dados)
    },

    getVendasParceiro(dados) {
        return http.post(`/vendas/relatorio-parceiro`, dados)
    },

    getConstulaPedido(dados) {
        return http.post(`/vendas/relatorio-consulta-pedido`, dados)
    },
    showTabelasVenda(queryParams = null) {
        const config = { validateStatus: () => true }
        const url = '/tabelas/venda'

        if (!queryParams) {
            return http.get(url, config)
        }

        const params = new URLSearchParams({
            produto_grupo_id: queryParams.produto_grupo_id,
            ...(queryParams.produto_tipo && {
                produto_tipo: queryParams.produto_tipo,
            }),
            ...(queryParams.produto_duracao && {
                produto_duracao: queryParams.produto_duracao,
            }),
            ...(queryParams.comissionado && {
                comissionado: queryParams.comissionado,
            }),
        })

        return http.get(`${url}?${params.toString()}`, config)
    },

    obterNfsePorVendaId(vendaId) {
        return http.get(`/v2/vendas/${vendaId}/nfse`, {
            validateStatus: () => true,
        })
    },

    atualizarStatusSoluti(vendaId) {
        return http.put(
            `/v2/vendas/${vendaId}/soluti/status`,
            {},
            { validateStatus: () => true }
        )
    },

    getExcel: (form) => {
        const url = '/v2/export/excel/relatorio-parceiro'
        form.isXls = true
        return http.post(url, form, {
            responseType: 'blob',
            validateStatus: () => true,
        })
    },
}
