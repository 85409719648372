<template>
    <div>
        <v-row>
            <v-col cols="12" class="titulo-pagamento">
                <span>Resumo da Compra</span>
            </v-col>
        </v-row>

        <v-row class="my-3">
            <v-col cols="12" sm="6" md="2">
                <span class="titulo-item">Nº Pedido</span><br />
                <span class="descricao-item">{{ venda.id }}</span>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <span class="titulo-item">CPF/CNPJ</span><br />
                <span class="descricao-item">{{
                    dados_certificado?.cpf_cnpj
                }}</span>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <span class="titulo-item">Nome/Razão</span><br />
                <span class="descricao-item">{{
                    dados_certificado?.nome_razao
                }}</span>
            </v-col>

            <v-col cols="12" sm="6" md="2">
                <span class="titulo-item">Data</span><br />
                <span class="descricao-item">{{
                    venda.created_at | formataData
                }}</span>
            </v-col>

            <v-col cols="12" sm="6" md="2">
                <span class="titulo-item">Valor Total</span><br />
                <span class="descricao-item">{{
                    venda.valor_total_venda | dinheiro
                }}</span>
            </v-col>
        </v-row>
        <hr />

        <v-row class="my-3">
            <v-col cols="12" sm="6" md="2">
                <span class="titulo-item">Cód. Produto</span><br />
                <span class="descricao-item">
                    {{ venda_item.prod_produto_id }}</span
                >
            </v-col>

            <v-col cols="12" sm="6" md="6">
                <span class="titulo-item">Descrição</span><br />
                <span class="descricao-item">
                    {{ venda_item.nome_produto }}</span
                >
            </v-col>

            <v-col cols="12" sm="6" md="2">
                <span class="titulo-item">Quantidade</span><br />
                <span class="descricao-item"> {{ venda_item.quantidade }}</span>
            </v-col>

            <v-col cols="12" sm="6" md="2">
                <span class="titulo-item">Valor</span><br />
                <span class="descricao-item">
                    {{ venda_item.valor_venda | dinheiro }}</span
                >
            </v-col>
        </v-row>
        <hr class="mb-10" />

        <div v-if="venda.venda_status_id === 2">
            <v-row justify="center">
                <v-col cols="12" class="align-center justify-center d-flex">
                    <IconAnimadoCheck
                        :size="60"
                        class="mx-5"
                    ></IconAnimadoCheck>
                    <span
                        style="
                            font-weight: bold;
                            font-size: 1.5em;
                            color: #4caf50;
                        "
                        >Pagamento efetuado!</span
                    >
                </v-col>
            </v-row>
        </div>

        <div v-else>
            <v-row>
                <v-col cols="12">
                    <span class="titulo-escolha"
                        >Escolha a forma de Pagamento</span
                    >
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <CardsFormas
                        :venda_id="venda.id"
                        :pagamento_obrigatorio="pagamento_obrigatorio"
                        @pagamentoEfetuado="$emit('pagamentoEfetuado', $event)"
                        :id_externo_venda="id_externo_venda"
                    ></CardsFormas>
                </v-col>
            </v-row>
        </div>

        <v-row>
            <v-col cols="2" class="d-flex justify-start">
                <v-btn color="blue" dark @click="voltarPasso"> Voltar </v-btn>
            </v-col>

            <v-col cols="2" offset="8" class="d-flex justify-end">
                <v-btn
                    color="green"
                    @click="proximoPasso"
                    :disabled="liberarBotaoProximoPasso"
                    :dark="!liberarBotaoProximoPasso"
                >
                    Próximo
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CardsFormas from '@/views/PaginasExternas/views/Pagamento/components/CardsFormas'
import IconAnimadoCheck from '@/components/IconesAnimados/Check.vue'
import { WebSocket } from '@/helpers/WebSocket'

export default {
    name: 'FormularioPagamento',
    components: { CardsFormas, IconAnimadoCheck },
    props: {
        pagamento_obrigatorio: [Boolean, Number],
        venda: [Object, Array],
        id_externo_venda: String,
        venda_item: [Object, Array],
        dados_certificado: [Object, Array],
    },
    mounted() {
        this.connectWS()
    },
    destroyed() {
        window.Echo.leaveChannel(`consulta-pagamento-venda.${this.venda.id}`)
    },
    computed: {
        liberarBotaoProximoPasso() {
            if (this.pagamento_obrigatorio) {
                return this.venda.venda_status_id !== 2
            }
            return false
        },
    },
    methods: {
        proximoPasso() {
            this.$emit('proximoPasso')
        },
        voltarPasso() {
            this.$emit('voltarPasso')
        },
        connectWS() {
            WebSocket.iniciarLaravelEcho()

            window.Echo.channel(
                `consulta-pagamento-venda.${this.venda.id}`
            ).listen('EventStatusPagamentoVenda', (event) => {
                if (event.venda_status_id === 2) {
                    window.dataLayer?.push({
                        'event': 'loja_pagamento_confirmado',
                        'loja_pagamento_confirmado': event
                    });
                    this.$emit('pagamentoEfetuado', event.venda_status_id)
                }
            })
        },
    },
}
</script>

<style>
.titulo-pagamento {
    font-size: 1.6em;
    border-radius: 7px;
    font-weight: bold;
}

.titulo-escolha {
    font-size: 1.2em;
}

.titulo-item {
    font-weight: bold;
    font-size: 1.1em;
}

.descricao-item {
    font-size: 1.1em;
}

hr {
    border: 0.5px solid;
    border-color: #dedede;
}
</style>
