<template>
    <div>
        <div @click="escolherProduto">
            <!--   Cor Verde e preto   -->
            <div v-if="chave_cor % 2 === 0">
                <div v-if="produto_escolhido_id !== produto_id">
                    <svg
                        width="380"
                        height="295"
                        viewBox="0 0 450 295"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_d_404_1879)">
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#585858"
                            />
                        </g>

                        <mask
                            id="mask0_404_1879"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="3"
                            y="3"
                            width="438"
                            height="283"
                        >
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#585858"
                            />
                        </mask>
                        <path
                            d="M317.609 46H227.391C220.558 46 215 51.5586 215 58.391V126.957C215 133.789 220.558 139.348 227.391 139.348H280.436V125.152C280.436 113.609 289.827 104.219 301.37 104.219C312.912 104.219 322.303 113.61 322.303 125.152V138.421C326.814 136.568 330 132.128 330 126.957V58.391C330 51.5586 324.441 46 317.609 46ZM247.239 60.6744H297.761C299.622 60.6744 301.13 62.1829 301.13 64.0435C301.13 65.9042 299.622 67.4127 297.761 67.4127H247.239C245.378 67.4127 243.87 65.9042 243.87 64.0435C243.87 62.1829 245.378 60.6744 247.239 60.6744ZM269.37 121.304H232.804C230.943 121.304 229.435 119.796 229.435 117.935C229.435 116.074 230.943 114.566 232.804 114.566H269.371C271.231 114.566 272.74 116.074 272.74 117.935C272.74 119.796 271.231 121.304 269.37 121.304ZM312.196 96.2826H232.804C230.943 96.2826 229.435 94.7742 229.435 92.9135C229.435 91.0528 230.943 89.5443 232.804 89.5443H312.196C314.057 89.5443 315.565 91.0528 315.565 92.9135C315.565 94.7742 314.057 96.2826 312.196 96.2826ZM312.196 81.8477H232.804C230.943 81.8477 229.435 80.3392 229.435 78.4785C229.435 76.6179 230.943 75.1094 232.804 75.1094H312.196C314.057 75.1094 315.565 76.6179 315.565 78.4785C315.565 80.3392 314.057 81.8477 312.196 81.8477Z"
                            fill="#303030"
                        />
                        <path
                            d="M301.369 110.957C293.542 110.957 287.174 117.325 287.174 125.152C287.174 132.98 293.542 139.347 301.369 139.347C309.196 139.347 315.564 132.98 315.564 125.152C315.564 117.325 309.196 110.957 301.369 110.957Z"
                            fill="#303030"
                        />
                        <path
                            d="M287.174 140.522V157.631C287.174 158.873 287.858 160.015 288.953 160.601C289.451 160.868 289.998 161 290.543 161C291.196 161 291.848 160.81 292.412 160.434L301.369 154.462L310.326 160.434C310.89 160.81 311.541 161 312.195 161C312.74 161 313.287 160.868 313.785 160.601C314.88 160.015 315.564 158.873 315.564 157.631V140.522C311.83 143.973 306.842 146.086 301.369 146.086C295.896 146.086 290.908 143.973 287.174 140.522Z"
                            fill="#303030"
                        />

                        <mask
                            id="mask0_404_2164"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="3"
                            y="3"
                            width="438"
                            height="283"
                        >
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#303030"
                            />
                        </mask>
                        <g mask="url(#mask0_404_2164)">
                            <circle
                                cx="-43.3602"
                                cy="140.64"
                                r="187.197"
                                stroke="#14D200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-43.3616"
                                cy="140.64"
                                r="141.172"
                                stroke="#14D200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-43.3598"
                                cy="140.64"
                                r="98.6879"
                                stroke="#14D200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-43.3612"
                                cy="140.64"
                                r="57.9736"
                                stroke="#14D200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="54.8864"
                                cy="141.525"
                                r="4.42547"
                                fill="#FFA200"
                            />
                            <circle
                                cx="138.084"
                                cy="185.779"
                                r="4.42547"
                                fill="#FFA200"
                            />
                            <circle
                                cx="46.0348"
                                cy="30.0036"
                                r="4.42547"
                                fill="#FFA200"
                            />
                            <circle
                                cx="84.9795"
                                cy="277.829"
                                r="2.65528"
                                fill="#FFA200"
                            />
                            <circle
                                cx="1.78028"
                                cy="104.351"
                                r="2.65528"
                                fill="#FFA200"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_d_404_2164"
                                x="0.2"
                                y="0.2"
                                width="449.6"
                                height="294.6"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dx="3" dy="3" />
                                <feGaussianBlur stdDeviation="2.9" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_404_2164"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_404_2164"
                                    result="shape"
                                />
                            </filter>
                        </defs>

                        <text
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="28"
                            font-family="Inter"
                            fill="#FFFFFF"
                            style="word-wrap: break-word; white-space: pre-wrap"
                            v-if="nome.length >= 10"
                        >
                            <tspan
                                v-for="(line, index) in lines"
                                x="60%"
                                :y="180 + index * lineHeight"
                                :key="index"
                            >
                                {{ line }}
                            </tspan>
                        </text>

                        <text
                            x="60%"
                            y="65%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="28"
                            font-family="Inter"
                            fill="#FFFFFF"
                            style="word-wrap: break-word"
                            v-else
                        >
                            {{ nome }}
                        </text>

                        <text
                            x="60%"
                            y="78%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="18"
                            font-family="Inter"
                            fill="#FFFFFF"
                        >
                            {{ valor_venda | dinheiro }}
                        </text>
                    </svg>
                </div>
                <div v-else>
                    <svg
                        width="380"
                        height="295"
                        viewBox="0 0 450 295"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_d_404_1965)">
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#01AE42"
                                fill-opacity="0.13"
                                shape-rendering="crispEdges"
                            />
                            <rect
                                x="4"
                                y="4"
                                width="436"
                                height="281"
                                rx="13"
                                stroke="#14D200"
                                stroke-width="2"
                                shape-rendering="crispEdges"
                            />
                        </g>
                        <path
                            d="M317.609 46H227.391C220.558 46 215 51.5586 215 58.391V126.957C215 133.789 220.558 139.348 227.391 139.348H280.436V125.152C280.436 113.609 289.827 104.219 301.37 104.219C312.912 104.219 322.303 113.61 322.303 125.152V138.421C326.814 136.568 330 132.128 330 126.957V58.391C330 51.5586 324.441 46 317.609 46ZM247.239 60.6744H297.761C299.622 60.6744 301.13 62.1829 301.13 64.0435C301.13 65.9042 299.622 67.4127 297.761 67.4127H247.239C245.378 67.4127 243.87 65.9042 243.87 64.0435C243.87 62.1829 245.378 60.6744 247.239 60.6744ZM269.37 121.304H232.804C230.943 121.304 229.435 119.796 229.435 117.935C229.435 116.074 230.943 114.566 232.804 114.566H269.371C271.231 114.566 272.74 116.074 272.74 117.935C272.74 119.796 271.231 121.304 269.37 121.304ZM312.196 96.2826H232.804C230.943 96.2826 229.435 94.7742 229.435 92.9135C229.435 91.0528 230.943 89.5443 232.804 89.5443H312.196C314.057 89.5443 315.565 91.0528 315.565 92.9135C315.565 94.7742 314.057 96.2826 312.196 96.2826ZM312.196 81.8477H232.804C230.943 81.8477 229.435 80.3392 229.435 78.4785C229.435 76.6179 230.943 75.1094 232.804 75.1094H312.196C314.057 75.1094 315.565 76.6179 315.565 78.4785C315.565 80.3392 314.057 81.8477 312.196 81.8477Z"
                            fill="#14D200"
                        />
                        <path
                            d="M301.369 110.957C293.542 110.957 287.174 117.325 287.174 125.152C287.174 132.98 293.542 139.347 301.369 139.347C309.196 139.347 315.564 132.98 315.564 125.152C315.564 117.325 309.196 110.957 301.369 110.957Z"
                            fill="#14D200"
                        />
                        <path
                            d="M287.174 140.522V157.631C287.174 158.873 287.858 160.015 288.953 160.601C289.451 160.868 289.998 161 290.543 161C291.196 161 291.848 160.81 292.412 160.434L301.369 154.462L310.326 160.434C310.89 160.81 311.541 161 312.195 161C312.74 161 313.287 160.868 313.785 160.601C314.88 160.015 315.564 158.873 315.564 157.631V140.522C311.83 143.973 306.842 146.086 301.369 146.086C295.896 146.086 290.908 143.973 287.174 140.522Z"
                            fill="#14D200"
                        />
                        <mask
                            id="mask0_404_1965"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="3"
                            y="3"
                            width="438"
                            height="283"
                        >
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#303030"
                            />
                        </mask>
                        <g mask="url(#mask0_404_1965)">
                            <circle
                                cx="-43.3602"
                                cy="140.64"
                                r="187.197"
                                stroke="#14D200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-43.3616"
                                cy="140.64"
                                r="141.172"
                                stroke="#14D200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-43.3598"
                                cy="140.64"
                                r="98.6879"
                                stroke="#14D200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-43.3612"
                                cy="140.64"
                                r="57.9736"
                                stroke="#14D200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="54.8864"
                                cy="141.525"
                                r="4.42547"
                                fill="#FFA200"
                            />
                            <circle
                                cx="138.084"
                                cy="185.779"
                                r="4.42547"
                                fill="#FFA200"
                            />
                            <circle
                                cx="46.0348"
                                cy="30.0036"
                                r="4.42547"
                                fill="#FFA200"
                            />
                            <circle
                                cx="84.9795"
                                cy="277.829"
                                r="2.65528"
                                fill="#FFA200"
                            />
                            <circle
                                cx="1.78028"
                                cy="104.351"
                                r="2.65528"
                                fill="#FFA200"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_d_404_1965"
                                x="0.2"
                                y="0.2"
                                width="449.6"
                                height="294.6"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dx="3" dy="3" />
                                <feGaussianBlur stdDeviation="2.9" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_404_1965"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_404_1965"
                                    result="shape"
                                />
                            </filter>
                        </defs>

                        <text
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="28"
                            font-family="Inter"
                            fill="#000000"
                            style="word-wrap: break-word; white-space: pre-wrap"
                            v-if="nome.length >= 10"
                        >
                            <tspan
                                v-for="(line, index) in lines"
                                x="60%"
                                :y="180 + index * lineHeight"
                                :key="index"
                            >
                                {{ line }}
                            </tspan>
                        </text>

                        <text
                            x="60%"
                            y="65%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="28"
                            font-family="Inter"
                            fill="#000000"
                            style="word-wrap: break-word"
                            v-else
                        >
                            {{ nome }}
                        </text>

                        <text
                            x="60%"
                            y="78%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="18"
                            font-family="Inter"
                            fill="#000000"
                        >
                            {{ valor_venda | dinheiro }}
                        </text>
                    </svg>
                </div>
            </div>

            <!--   Cor Laranja e preto   -->
            <div v-if="chave_cor % 2 !== 0">
                <div v-if="produto_escolhido_id !== produto_id">
                    <svg
                        width="380"
                        height="295"
                        viewBox="0 0 450 295"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_d_404_1879)">
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#585858"
                            />
                        </g>

                        <mask
                            id="mask0_404_1879"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="3"
                            y="3"
                            width="438"
                            height="283"
                        >
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#585858"
                            />
                        </mask>
                        <path
                            d="M317.609 46H227.391C220.558 46 215 51.5586 215 58.391V126.957C215 133.789 220.558 139.348 227.391 139.348H280.436V125.152C280.436 113.609 289.827 104.219 301.37 104.219C312.912 104.219 322.303 113.61 322.303 125.152V138.421C326.814 136.568 330 132.128 330 126.957V58.391C330 51.5586 324.441 46 317.609 46ZM247.239 60.6744H297.761C299.622 60.6744 301.13 62.1829 301.13 64.0435C301.13 65.9042 299.622 67.4127 297.761 67.4127H247.239C245.378 67.4127 243.87 65.9042 243.87 64.0435C243.87 62.1829 245.378 60.6744 247.239 60.6744ZM269.37 121.304H232.804C230.943 121.304 229.435 119.796 229.435 117.935C229.435 116.074 230.943 114.566 232.804 114.566H269.371C271.231 114.566 272.74 116.074 272.74 117.935C272.74 119.796 271.231 121.304 269.37 121.304ZM312.196 96.2826H232.804C230.943 96.2826 229.435 94.7742 229.435 92.9135C229.435 91.0528 230.943 89.5443 232.804 89.5443H312.196C314.057 89.5443 315.565 91.0528 315.565 92.9135C315.565 94.7742 314.057 96.2826 312.196 96.2826ZM312.196 81.8477H232.804C230.943 81.8477 229.435 80.3392 229.435 78.4785C229.435 76.6179 230.943 75.1094 232.804 75.1094H312.196C314.057 75.1094 315.565 76.6179 315.565 78.4785C315.565 80.3392 314.057 81.8477 312.196 81.8477Z"
                            fill="#303030"
                        />
                        <path
                            d="M301.369 110.957C293.542 110.957 287.174 117.325 287.174 125.152C287.174 132.98 293.542 139.347 301.369 139.347C309.196 139.347 315.564 132.98 315.564 125.152C315.564 117.325 309.196 110.957 301.369 110.957Z"
                            fill="#303030"
                        />
                        <path
                            d="M287.174 140.522V157.631C287.174 158.873 287.858 160.015 288.953 160.601C289.451 160.868 289.998 161 290.543 161C291.196 161 291.848 160.81 292.412 160.434L301.369 154.462L310.326 160.434C310.89 160.81 311.541 161 312.195 161C312.74 161 313.287 160.868 313.785 160.601C314.88 160.015 315.564 158.873 315.564 157.631V140.522C311.83 143.973 306.842 146.086 301.369 146.086C295.896 146.086 290.908 143.973 287.174 140.522Z"
                            fill="#303030"
                        />

                        <g mask="url(#mask0_404_1879)">
                            <circle
                                cx="-40.3602"
                                cy="140.64"
                                r="187.197"
                                stroke="#FFA200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-40.3597"
                                cy="140.639"
                                r="141.172"
                                stroke="#FFA200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-40.3598"
                                cy="140.639"
                                r="98.6879"
                                stroke="#FFA200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-40.3612"
                                cy="140.64"
                                r="57.9736"
                                stroke="#FFA200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="57.8844"
                                cy="141.525"
                                r="4.42547"
                                fill="#14D200"
                            />
                            <circle
                                cx="141.084"
                                cy="185.779"
                                r="4.42547"
                                fill="#14D200"
                            />
                            <circle
                                cx="49.0348"
                                cy="30.0026"
                                r="4.42547"
                                fill="#14D200"
                            />
                            <circle
                                cx="87.9775"
                                cy="277.829"
                                r="2.65528"
                                fill="#14D200"
                            />
                            <circle
                                cx="4.78028"
                                cy="104.351"
                                r="2.65528"
                                fill="#14D200"
                            />
                        </g>

                        <defs>
                            <filter
                                id="filter0_d_404_1879"
                                x="0.2"
                                y="0.2"
                                width="449.6"
                                height="294.6"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dx="3" dy="3" />
                                <feGaussianBlur stdDeviation="2.9" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_404_1879"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_404_1879"
                                    result="shape"
                                />
                            </filter>
                        </defs>
                        <text
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="28"
                            font-family="Inter"
                            fill="#FFFFFF"
                            style="word-wrap: break-word; white-space: pre-wrap"
                            v-if="nome.length >= 10"
                        >
                            <tspan
                                v-for="(line, index) in lines"
                                x="60%"
                                :y="180 + index * lineHeight"
                                :key="index"
                            >
                                {{ line }}
                            </tspan>
                        </text>

                        <text
                            x="60%"
                            y="65%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="28"
                            font-family="Inter"
                            fill="#FFFFFF"
                            style="word-wrap: break-word"
                            v-else
                        >
                            {{ nome }}
                        </text>

                        <text
                            x="60%"
                            y="78%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="18"
                            font-family="Inter"
                            fill="#FFFFFF"
                        >
                            {{ valor_venda | dinheiro }}
                        </text>
                    </svg>
                </div>

                <div v-else>
                    <svg
                        width="380"
                        height="295"
                        viewBox="0 0 450 295"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_d_404_2112)">
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#FFA200"
                                fill-opacity="0.13"
                                shape-rendering="crispEdges"
                            />
                            <rect
                                x="4"
                                y="4"
                                width="436"
                                height="281"
                                rx="13"
                                stroke="#FFA200"
                                stroke-width="2"
                                shape-rendering="crispEdges"
                            />
                        </g>
                        <mask
                            id="mask0_404_2112"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="3"
                            y="3"
                            width="438"
                            height="283"
                        >
                            <rect
                                x="3"
                                y="3"
                                width="438"
                                height="283"
                                rx="14"
                                fill="#303030"
                            />
                        </mask>
                        <g mask="url(#mask0_404_2112)">
                            <circle
                                cx="-40.3602"
                                cy="140.64"
                                r="187.197"
                                stroke="#FFA200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-40.3616"
                                cy="140.64"
                                r="141.172"
                                stroke="#FFA200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-40.3598"
                                cy="140.64"
                                r="98.6879"
                                stroke="#FFA200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="-40.3612"
                                cy="140.64"
                                r="57.9736"
                                stroke="#FFA200"
                                stroke-width="0.885093"
                            />
                            <circle
                                cx="57.8864"
                                cy="141.525"
                                r="4.42547"
                                fill="#14D200"
                            />
                            <circle
                                cx="141.084"
                                cy="185.779"
                                r="4.42547"
                                fill="#14D200"
                            />
                            <circle
                                cx="49.0348"
                                cy="30.0036"
                                r="4.42547"
                                fill="#14D200"
                            />
                            <circle
                                cx="87.9795"
                                cy="277.829"
                                r="2.65528"
                                fill="#14D200"
                            />
                            <circle
                                cx="4.78028"
                                cy="104.351"
                                r="2.65528"
                                fill="#14D200"
                            />
                        </g>
                        <path
                            d="M317.609 46H227.391C220.558 46 215 51.5586 215 58.391V126.957C215 133.789 220.558 139.348 227.391 139.348H280.436V125.152C280.436 113.609 289.827 104.219 301.37 104.219C312.912 104.219 322.303 113.61 322.303 125.152V138.421C326.814 136.568 330 132.128 330 126.957V58.391C330 51.5586 324.441 46 317.609 46ZM247.239 60.6744H297.761C299.622 60.6744 301.13 62.1829 301.13 64.0435C301.13 65.9042 299.622 67.4127 297.761 67.4127H247.239C245.378 67.4127 243.87 65.9042 243.87 64.0435C243.87 62.1829 245.378 60.6744 247.239 60.6744ZM269.37 121.304H232.804C230.943 121.304 229.435 119.796 229.435 117.935C229.435 116.074 230.943 114.566 232.804 114.566H269.371C271.231 114.566 272.74 116.074 272.74 117.935C272.74 119.796 271.231 121.304 269.37 121.304ZM312.196 96.2826H232.804C230.943 96.2826 229.435 94.7742 229.435 92.9135C229.435 91.0528 230.943 89.5443 232.804 89.5443H312.196C314.057 89.5443 315.565 91.0528 315.565 92.9135C315.565 94.7742 314.057 96.2826 312.196 96.2826ZM312.196 81.8477H232.804C230.943 81.8477 229.435 80.3392 229.435 78.4785C229.435 76.6179 230.943 75.1094 232.804 75.1094H312.196C314.057 75.1094 315.565 76.6179 315.565 78.4785C315.565 80.3392 314.057 81.8477 312.196 81.8477Z"
                            fill="#FFA200"
                        />
                        <path
                            d="M301.369 110.957C293.542 110.957 287.174 117.325 287.174 125.152C287.174 132.98 293.542 139.347 301.369 139.347C309.196 139.347 315.564 132.98 315.564 125.152C315.564 117.325 309.196 110.957 301.369 110.957Z"
                            fill="#FFA200"
                        />
                        <path
                            d="M287.174 140.522V157.631C287.174 158.873 287.858 160.015 288.953 160.601C289.451 160.868 289.998 161 290.543 161C291.196 161 291.848 160.81 292.412 160.434L301.369 154.462L310.326 160.434C310.89 160.81 311.541 161 312.195 161C312.74 161 313.287 160.868 313.785 160.601C314.88 160.015 315.564 158.873 315.564 157.631V140.522C311.83 143.973 306.842 146.086 301.369 146.086C295.896 146.086 290.908 143.973 287.174 140.522Z"
                            fill="#FFA200"
                        />
                        <defs>
                            <filter
                                id="filter0_d_404_2112"
                                x="0.2"
                                y="0.2"
                                width="449.6"
                                height="294.6"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset dx="3" dy="3" />
                                <feGaussianBlur stdDeviation="2.9" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_404_2112"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_404_2112"
                                    result="shape"
                                />
                            </filter>
                        </defs>

                        <text
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="28"
                            font-family="Inter"
                            fill="#000000"
                            style="word-wrap: break-word; white-space: pre-wrap"
                            v-if="nome.length >= 10"
                        >
                            <tspan
                                v-for="(line, index) in lines"
                                x="60%"
                                :y="180 + index * lineHeight"
                                :key="index"
                            >
                                {{ line }}
                            </tspan>
                        </text>

                        <text
                            x="60%"
                            y="65%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="28"
                            font-family="Inter"
                            fill="#000000"
                            style="word-wrap: break-word"
                            v-else
                        >
                            {{ nome }}
                        </text>

                        <text
                            x="60%"
                            y="78%"
                            dominant-baseline="middle"
                            text-anchor="middle"
                            font-size="18"
                            font-family="Inter"
                            fill="#000000"
                        >
                            {{ valor_venda | dinheiro }}
                        </text>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProdutoParaSelecionar',
    data: () => ({}),
    props: {
        chave_cor: Number,
        produto_id: Number,
        nome: String,
        disabled: Boolean,
        valor_venda: [String, Number],
        produto_escolhido_id: Number,
    },
    computed: {
        lines() {
            const words = this.nome.split(' ')
            const lines = []
            for (let i = 0; i < words.length; i += 3) {
                lines.push(words.slice(i, i + 3).join(' '))
            }
            return lines
        },
        lineHeight() {
            // Adjust line height according to your text size
            return 25
        },
    },
    methods: {
        escolherProduto() {
            if (!this.disabled) {
                this.$emit('produtoEscolhido', this.produto_id)
            }
        },
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
</style>
