export const Formatter = {
    emptyToNull(value) {
        if (value === null || value === undefined) {
            return null
        }

        if (value === '') {
            return null
        }

        if (Array.isArray(value) && value.length === 0) {
            return null
        }

        if (typeof value === 'object' && Object.keys(value).length === 0) {
            return null
        }

        return value
    },

    arrayLength(value) {
        if (value === null || value === undefined) {
            return 0
        }

        if (Array.isArray(value)) {
            return value.length
        }

        return 0
    },

    stringLimit(string, limit = 50) {
        if (string === null || string === undefined || string === '') {
            return null
        }

        if (string.length > limit) {
            return string.substring(0, limit).trim() + '...'
        }

        return string
    },

    todayFormatted() {
        return this.formatDate(new Date())
    },

    pastDateFormated(pastDays) {
        const today = new Date()
        const pastDate = new Date(
            today.getTime() - pastDays * 24 * 60 * 60 * 1000
        )
        return this.formatDate(pastDate)
    },

    formatDate(date) {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')

        return year + '-' + month + '-' + day
    },

    BrazilianDate(date) {
        if (!(date instanceof Date)) {
            date = new Date(date + 'T00:00:00')
        }

        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')

        return day + '/' + month + '/' + year
    },

    roundTwoDecimals(value) {
        if (isNaN(value) || value === 0) {
            return 0
        }

        return Math.round(value * 100) / 100
    },

    formatDocument(value) {
        if (value === null || value === undefined || value === '') {
            return null
        }

        if (value.length === 11) {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        }

        if (value.length === 14) {
            return value.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5'
            )
        }

        return value
    },
}
