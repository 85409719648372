import { render, staticRenderFns } from "./CardsFormas.vue?vue&type=template&id=035777c2"
import script from "./CardsFormas.vue?vue&type=script&lang=js"
export * from "./CardsFormas.vue?vue&type=script&lang=js"
import style0 from "./CardsFormas.vue?vue&type=style&index=0&id=035777c2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports