<template>
    <div style="height: 100%">
        <v-container style="height: 100%">
            <v-row class="d-flex justify-center align-center">
                <v-col cols="12" sm="8" md="6" lg="4">
                    <v-img :src="logo"></v-img>
                </v-col>
            </v-row>

            <v-row
                class="d-flex justify-center align-center"
                style="height: 80%"
            >
                <v-col cols="5" xs="12">
                    <span
                        style="font-size: 6em"
                        class="float-right font-weight-bold"
                    >
                        404
                    </span>
                </v-col>

                <v-col cols="7" xs="12">
                    <span style="font-size: 2.3em" class="font-weight-bold">
                        Pagina não encontrada</span
                    ><br />
                    <span style="font-size: 1.3em" class="font-weight-bold">
                        Desculpe, mas essa pagina não existe...</span
                    >
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    name: 'PaginaNaoEncontrada',
    data: () => ({
      logo: "/img/logotipo.png"
    }),
}
</script>
