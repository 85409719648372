<script>
import { services } from '@/services/PaginasExternasServices'
import messages from '@/mixins/mixMessages'
import { Formatter } from '@/helpers/Formatter'
import InputDocumentosAlt from '@/components/Inputs/InputDocumentosAlt.vue'
import InputDocumentos from '@/components/Inputs/InputDocumentos.vue'

export default {
    name: 'DocumentacaoVenda',
    components: {
        InputDocumentos, // eslint-disable-line vue/no-unused-components
        InputDocumentosAlt,
    },
    mixins: [messages],
    data: () => ({
        idExterno: null,
        venda: null,
        files: [],
        isLoading: false,
    }),
    computed: {
        Formatter() {
            return Formatter
        },
    },
    async created() {
        this.$store.commit('setStatus', true)
        this.idExterno = this.$route.params.id
        await this.getVenda()
        this.$store.commit('setStatus', false)
    },
    methods: {
        async getVenda() {
            const response = await services.getVendaDocumentacaoPendente(
                this.idExterno
            )

            if (response.status !== 200) {
                await this.$router.push('/web/link-expirado')
                return
            }

            this.venda = response.data.data
            this.$emit(
                'setBackgroundColor',
                this.venda.personalizacao.cor_pagina
            )
        },

        async enviarArquivo() {
            if (!this.files) {
                this.message('Anexe pelo menos um arquivo')
                return
            }

            const formData = new FormData()
            formData.append('id', this.idExterno)
            this.files.forEach((doc) => {
                formData.append(`files[]`, doc.file)
            })

            this.isLoading = true
            const response = await services.storeDocumentacaoPendente(formData)
            this.isLoading = false

            if (response.status !== 200) {
                const mensagem =
                    response.data.message || 'Erro ao salvar Documentos'
                this.$notify({
                    type: 'error',
                    title: 'Erro:',
                    text: mensagem,
                })
                return
            }

            this.$notify({
                type: 'success',
                title: 'Sucesso',
                text:
                    response.data.message ||
                    'Tudo certo, arquivos atualizados .',
            })
        },
    },
}
</script>

<template>
    <v-container v-if="venda !== null" style="max-width: 1024px">
        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="12" align="center">
                        <v-img
                            :src="venda.personalizacao.link_logo"
                            max-width="100%"
                            max-height="75"
                            contain
                            class="my-2"
                        ></v-img>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col
                        cols="12"
                        class="d-flex align-center justify-center"
                        style="
                            background: rgba(0, 190, 56, 0.34);
                            border-radius: 7px;
                        "
                    >
                        <span style="font-size: 1.7em; font-weight: 500">
                            CERTIFICADO DIGITAL COM PENDÊNCIAS
                        </span>
                    </v-col>
                </v-row>
                <div>
                    <v-row id="corpo-pagina">
                        <v-col>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                    <p
                                        style="
                                            max-width: 750px;
                                            text-align: center;
                                        "
                                    >
                                        Falta pouco para o certificado digital
                                        ser liberado para instalação.<br />
                                        É necessário que nos envie o(s)
                                        documento(s) abaixo para que seja
                                        concluído seu processo.
                                    </p>
                                </v-col>
                                <v-col cols="4" class="d-flex flex-column">
                                    <h1 class="title-col">
                                        DETALHES DO PEDIDO:
                                    </h1>
                                    <p class="item-list">
                                        <strong>NOME:</strong>
                                        {{ this.venda.certificado.nome_razao }}
                                    </p>
                                    <p class="item-list">
                                        <strong>CPF/CNPJ: </strong>
                                        {{
                                            Formatter.formatDocument(
                                                this.venda.certificado.cpf_cnpj
                                            )
                                        }}
                                    </p>
                                    <p class="item-list">
                                        <strong>TIPO CERTIFICADO: </strong>
                                        {{ this.venda.venda_item.nome_produto }}
                                    </p>
                                    <p class="item-list">
                                        <strong>N° PEDIDO: </strong>
                                        {{ this.venda.venda_id }}
                                    </p>
                                    <h1 class="title-col mt-6">
                                        DOCUMENTO PENDENTE:
                                    </h1>
                                    <div
                                        class="d-flex align-center mt-1"
                                        style="gap: 5px"
                                    >
                                        <svg
                                            width="33"
                                            height="33"
                                            viewBox="0 0 33 33"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.3421 12.0859C19.3192 11.4496 19.1096 10.834 18.7394 10.316C18.3692 9.79799 17.8548 9.40034 17.2602 9.17261C16.6656 8.94489 16.0171 8.89716 15.3956 9.03536C14.7741 9.17356 14.207 9.4916 13.7649 9.94983C13.3228 10.4081 13.0254 10.9863 12.9096 11.6123C12.7938 12.2384 12.8648 12.8848 13.1137 13.4708C13.3626 14.0568 13.7785 14.5567 14.3095 14.908C14.8405 15.2594 15.4631 15.4467 16.0998 15.4467C16.9751 15.43 17.8081 15.0668 18.4159 14.4367C19.0237 13.8066 19.3568 12.9612 19.3421 12.0859Z"
                                                fill="#1A1A1A"
                                            />
                                            <path
                                                d="M11.9978 15.4329C10.8681 16.0642 9.89614 16.9432 9.15476 18.0039C8.41338 19.0646 7.92189 20.2795 7.71717 21.5573C7.67812 21.7952 7.69151 22.0388 7.7564 22.2711C7.82129 22.5033 7.93612 22.7186 8.09286 22.9018C8.24351 23.0817 8.43183 23.2262 8.64451 23.3253C8.85719 23.4244 9.08903 23.4755 9.32364 23.4752H22.8773C23.1119 23.4755 23.3438 23.4244 23.5565 23.3253C23.7691 23.2262 23.9575 23.0817 24.1081 22.9018C24.2649 22.7186 24.3797 22.5033 24.4446 22.2711C24.5095 22.0388 24.5229 21.7952 24.4838 21.5573C24.2791 20.2795 23.7876 19.0646 23.0462 18.0039C22.3048 16.9432 21.3328 16.0642 20.2032 15.4329C20.1074 15.379 19.9963 15.359 19.8878 15.376C19.7793 15.3931 19.6797 15.4462 19.6051 15.5268C19.1548 16.0045 18.6117 16.385 18.009 16.6451C17.4064 16.9052 16.7569 17.0394 16.1005 17.0394C15.4441 17.0394 14.7946 16.9052 14.192 16.6451C13.5893 16.385 13.0462 16.0045 12.5959 15.5268C12.5213 15.4462 12.4217 15.3931 12.3132 15.376C12.2046 15.359 12.0935 15.379 11.9978 15.4329Z"
                                                fill="#1A1A1A"
                                            />
                                            <path
                                                d="M3.98871 8.06814C3.99044 7.03939 4.40016 6.05334 5.12798 5.32629C5.8558 4.59924 6.84228 4.19057 7.87103 4.18992H10.9835V2.21289H7.87104C6.31814 2.21412 4.82913 2.83128 3.73068 3.92897C2.63223 5.02665 2.01403 6.51523 2.01172 8.06814V10.9883H3.98871V8.06814Z"
                                                fill="#1A1A1A"
                                            />
                                            <path
                                                d="M28.2096 24.1311C28.2079 25.1598 27.7982 26.1459 27.0704 26.8729C26.3425 27.6 25.3561 28.0086 24.3273 28.0093H21.2148V29.9863H24.3273C25.8802 29.9851 27.3692 29.3679 28.4677 28.2702C29.5661 27.1725 30.1843 25.684 30.1866 24.1311V21.2109H28.2096V24.1311Z"
                                                fill="#1A1A1A"
                                            />
                                            <path
                                                d="M7.87104 29.9863H10.9835V28.0093H7.87104C6.8423 28.0086 5.85581 27.6 5.12799 26.8729C4.40017 26.1459 3.99046 25.1598 3.98873 24.1311V21.2109H2.01172V24.1311C2.01404 25.684 2.63223 27.1725 3.73068 28.2702C4.82913 29.3679 6.31814 29.9851 7.87104 29.9863Z"
                                                fill="#1A1A1A"
                                            />
                                            <path
                                                d="M24.3273 2.21289H21.2148V4.18988H24.3273C25.3561 4.19053 26.3425 4.5992 27.0704 5.32625C27.7982 6.0533 28.2079 7.03935 28.2096 8.0681V10.9882H30.1866V8.06814C30.1843 6.51523 29.5661 5.02665 28.4677 3.92897C27.3692 2.83128 25.8802 2.21412 24.3273 2.21289Z"
                                                fill="#1A1A1A"
                                            />
                                        </svg>
                                        <p class="item-list">
                                            {{
                                                this.venda.documentacao_venda
                                                    .pendencias
                                            }}
                                        </p>
                                    </div>
                                </v-col>
                                <v-col cols="4"> </v-col>
                                <v-col
                                    cols="4"
                                    class="d-flex flex-column justify-center"
                                >
                                    <h1
                                        style="
                                            text-align: center;
                                            font-size: 20px;
                                            color: #4c535f;
                                            font-weight: 400;
                                        "
                                    >
                                        Anexar Documento
                                    </h1>
                                    <InputDocumentosAlt
                                        :modelValue="files"
                                        @update:modelValue="files = $event"
                                    />
                                    <v-btn
                                        style="width: 150px; align-self: center"
                                        color="#14d200"
                                        :dark="!isLoading"
                                        :disabled="isLoading"
                                        :loading="isLoading"
                                        @click="enviarArquivo"
                                    >
                                        Enviar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="venda.documentacao_venda.observacao">
                                <v-col cols="12" class="d-flex flex-column">
                                    <h1 class="title-col">OBSERVAÇÃO:</h1>
                                    <p
                                        class="item-list"
                                        v-html="
                                            venda.documentacao_venda.observacao
                                        "
                                    ></p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            class="d-flex flex-column justify-center align-center text-center"
                        >
                            <p class="font-weight-bold">
                                FICOU COM ALGUMA DÚVIDA?
                                <a
                                    :href="
                                        'tel:+' + venda.personalizacao.telefone
                                    "
                                >
                                    {{ this.venda.personalizacao.telefone }}
                                </a>
                            </p>
                            <div
                                class="d-flex justify-space-between"
                                style="gap: 30px"
                            >
                                <a class="btn-chat">Chat</a>
                                <a class="btn-whats">Whatsapp</a>
                            </div>
                            <div class="mt-10">
                                <img
                                    :src="venda.personalizacao.link_logo"
                                    alt="logo empresa"
                                    width="140"
                                />
                            </div>
                            <p style="color: grey">
                                {{ this.venda.personalizacao.telefone }}
                                {{ this.venda.personalizacao.email }}
                            </p>
                            <div class="d-flex" style="gap: 35px">
                                <a
                                    v-if="venda.personalizacao.url_whatsapp"
                                    :href="venda.personalizacao.url_whatsapp"
                                    target="_blank"
                                >
                                    <svg
                                        width="33"
                                        height="33"
                                        viewBox="0 0 33 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.1267 32.8398C25.0333 32.8398 32.2534 25.5704 32.2534 16.603C32.2534 7.63568 25.0333 0.366211 16.1267 0.366211C7.22018 0.366211 0 7.63568 0 16.603C0 25.5704 7.22018 32.8398 16.1267 32.8398Z"
                                            fill="#29A71A"
                                        />
                                        <path
                                            d="M23.2396 9.44445C21.5617 7.73829 19.3336 6.69196 16.957 6.49419C14.5804 6.29642 12.2118 6.96023 10.2785 8.36589C8.34519 9.77154 6.97443 11.8265 6.41346 14.1601C5.85249 16.4937 6.13824 18.9523 7.21916 21.0925L6.1581 26.2791C6.14709 26.3307 6.14678 26.384 6.15718 26.4358C6.16759 26.4875 6.18849 26.5366 6.21857 26.5798C6.26265 26.6455 6.32556 26.696 6.39891 26.7247C6.47226 26.7533 6.55255 26.7588 6.62907 26.7403L11.6778 25.5355C13.7975 26.5963 16.2222 26.8654 18.5205 26.2952C20.8189 25.7249 22.8417 24.3522 24.2291 22.4213C25.6165 20.4904 26.2785 18.1265 26.0973 15.7502C25.9161 13.374 24.9035 11.1395 23.2396 9.44445ZM21.6654 22.0944C20.5045 23.2599 19.0096 24.0293 17.3914 24.2941C15.7732 24.559 14.1132 24.3058 12.6454 23.5705L11.9417 23.2199L8.8465 23.9579L8.85566 23.9192L9.49706 20.7825L9.15254 20.098C8.40257 18.6151 8.13801 16.9312 8.39675 15.2876C8.6555 13.644 9.42427 12.125 10.5929 10.9482C12.0614 9.47018 14.0528 8.63987 16.1292 8.63987C18.2056 8.63987 20.197 9.47018 21.6654 10.9482C21.6779 10.9626 21.6914 10.9762 21.7057 10.9888C23.156 12.4706 23.9656 14.4696 23.958 16.55C23.9505 18.6303 23.1264 20.6233 21.6654 22.0944Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M21.3923 19.794C21.0129 20.3955 20.4137 21.1317 19.6605 21.3143C18.341 21.6354 16.316 21.3254 13.7962 18.96L13.7651 18.9323C11.5495 16.864 10.974 15.1425 11.1133 13.7771C11.1903 13.0022 11.8317 12.3011 12.3723 11.8435C12.4578 11.77 12.5591 11.7178 12.6683 11.6908C12.7774 11.6638 12.8913 11.663 13.0008 11.6883C13.1103 11.7135 13.2124 11.7643 13.299 11.8364C13.3856 11.9085 13.4542 12.0001 13.4993 12.1037L14.3148 13.9487C14.3678 14.0684 14.3875 14.2003 14.3717 14.3303C14.3558 14.4603 14.3052 14.5836 14.225 14.6868L13.8127 15.2255C13.7242 15.3368 13.6709 15.4721 13.6594 15.6142C13.648 15.7562 13.679 15.8985 13.7486 16.0226C13.9795 16.4304 14.5329 17.03 15.1468 17.5854C15.8359 18.2127 16.6001 18.7866 17.0839 18.9822C17.2133 19.0354 17.3557 19.0484 17.4925 19.0195C17.6294 18.9905 17.7545 18.921 17.8517 18.8198L18.33 18.3345C18.4223 18.2429 18.5371 18.1775 18.6626 18.1451C18.7882 18.1127 18.92 18.1144 19.0447 18.15L20.9818 18.7035C21.0886 18.7365 21.1866 18.7937 21.2681 18.8707C21.3497 18.9476 21.4126 19.0423 21.4523 19.1476C21.4919 19.2528 21.5071 19.3657 21.4967 19.4778C21.4863 19.5898 21.4506 19.698 21.3923 19.794Z"
                                            fill="white"
                                        />
                                    </svg>
                                </a>
                                <a
                                    v-if="venda.personalizacao.url_instagram"
                                    :href="venda.personalizacao.url_instagram"
                                    target="_blank"
                                >
                                    <svg
                                        width="34"
                                        height="33"
                                        viewBox="0 0 34 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M33.3199 16.6059C33.3199 7.63861 26.0997 0.369141 17.1931 0.369141C8.28659 0.369141 1.06641 7.63861 1.06641 16.6059C1.06641 25.5733 8.28659 32.8428 17.1931 32.8428C26.0997 32.8428 33.3199 25.5733 33.3199 16.6059Z"
                                            fill="url(#paint0_linear_1294_734)"
                                        />
                                        <path
                                            opacity="0.1"
                                            d="M29.3319 5.9247C26.9808 10.2702 23.6557 14.7524 19.501 18.9355C15.3462 23.1186 10.8944 26.46 6.58475 28.8271C6.31456 28.5907 6.05214 28.3443 5.79751 28.0879C4.25838 26.59 3.03084 24.7986 2.18647 22.8181C1.34211 20.8376 0.897814 18.7077 0.879497 16.5525C0.86118 14.3973 1.26921 12.2601 2.07979 10.2653C2.89037 8.27053 4.08728 6.45822 5.60072 4.93401C7.11417 3.40981 8.91387 2.20422 10.8949 1.38755C12.8759 0.570883 14.9986 0.159474 17.1391 0.177314C19.2797 0.195154 21.3953 0.641887 23.3626 1.49146C25.3299 2.34104 27.1096 3.57646 28.5977 5.12568C28.8524 5.38205 29.0971 5.64839 29.3319 5.9247Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M21.0182 8.06152H13.3792C12.1411 8.06152 10.9537 8.55671 10.0782 9.43816C9.20277 10.3196 8.71094 11.5151 8.71094 12.7617V20.4528C8.71094 21.6993 9.20277 22.8948 10.0782 23.7763C10.9537 24.6577 12.1411 25.1529 13.3792 25.1529H21.0182C22.2563 25.1529 23.4437 24.6577 24.3191 23.7763C25.1946 22.8948 25.6864 21.6993 25.6864 20.4528V12.7617C25.6864 11.5151 25.1946 10.3196 24.3191 9.43816C23.4437 8.55671 22.2563 8.06152 21.0182 8.06152ZM24.2011 19.7777C24.2011 20.8066 23.7951 21.7935 23.0724 22.5211C22.3498 23.2486 21.3696 23.6574 20.3476 23.6574H14.0497C13.0277 23.6574 12.0476 23.2486 11.3249 22.5211C10.6023 21.7935 10.1963 20.8066 10.1963 19.7777V13.4368C10.1963 12.4078 10.6023 11.421 11.3249 10.6934C12.0476 9.96578 13.0277 9.55702 14.0497 9.55702H20.3476C21.3696 9.55702 22.3498 9.96578 23.0724 10.6934C23.7951 11.421 24.2011 12.4078 24.2011 13.4368V19.7777Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M20.2955 13.5224L20.2552 13.4818L20.2213 13.4476C19.419 12.6426 18.3323 12.1909 17.1996 12.1914C16.6276 12.1953 16.062 12.3127 15.5351 12.5367C15.0082 12.7608 14.5302 13.0872 14.1286 13.4973C13.727 13.9074 13.4096 14.3931 13.1946 14.9268C12.9795 15.4604 12.8709 16.0315 12.8751 16.6074C12.8743 17.7761 13.3318 18.8979 14.1483 19.7287C14.5482 20.1362 15.0247 20.4595 15.5501 20.6796C16.0755 20.8997 16.6391 21.0122 17.2081 21.0106C18.0616 20.9925 18.8915 20.7248 19.5965 20.2401C20.3015 19.7554 20.8512 19.0746 21.1785 18.2807C21.5058 17.4869 21.5966 16.6143 21.4398 15.7694C21.2831 14.9244 20.8855 14.1437 20.2955 13.5224ZM17.1996 19.5044C16.6289 19.5124 16.0687 19.3494 15.5902 19.036C15.1118 18.7226 14.7367 18.273 14.5127 17.7444C14.2888 17.2158 14.226 16.6321 14.3324 16.0675C14.4388 15.5029 14.7097 14.9829 15.1104 14.5737C15.5112 14.1645 16.0237 13.8845 16.583 13.7694C17.1422 13.6543 17.7228 13.7092 18.2509 13.9273C18.779 14.1453 19.2308 14.5165 19.5488 14.9938C19.8668 15.471 20.0366 16.0327 20.0367 16.6074C20.0395 16.9852 19.9683 17.3599 19.8272 17.71C19.6861 18.0601 19.4779 18.3788 19.2144 18.6478C18.9509 18.9168 18.6374 19.131 18.2917 19.2779C17.946 19.4249 17.5749 19.5019 17.1996 19.5044Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M22.8059 11.9892C22.807 12.1263 22.7813 12.2623 22.7301 12.3894C22.679 12.5164 22.6035 12.6321 22.5079 12.7297C22.4123 12.8273 22.2985 12.905 22.173 12.9583C22.0476 13.0116 21.9129 13.0395 21.7768 13.0403C21.6417 13.0403 21.5081 13.0133 21.3835 12.9608C21.2589 12.9084 21.1459 12.8316 21.0511 12.7348C20.9073 12.588 20.8095 12.4019 20.7698 12.1996C20.7301 11.9973 20.7503 11.7878 20.8278 11.5969C20.9053 11.4061 21.0367 11.2423 21.2058 11.126C21.3749 11.0096 21.5741 10.9458 21.7789 10.9424C22.0185 10.9423 22.2506 11.027 22.4346 11.1817L22.4558 11.203C22.49 11.2311 22.5213 11.2626 22.5491 11.297L22.5725 11.3227C22.7244 11.5111 22.8068 11.7466 22.8059 11.9892Z"
                                            fill="white"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear_1294_734"
                                                x1="5.78984"
                                                y1="5.12482"
                                                x2="28.7516"
                                                y2="27.9309"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stop-color="#FAE100" />
                                                <stop
                                                    offset="0.15"
                                                    stop-color="#FCB720"
                                                />
                                                <stop
                                                    offset="0.3"
                                                    stop-color="#FF7950"
                                                />
                                                <stop
                                                    offset="0.5"
                                                    stop-color="#FF1C74"
                                                />
                                                <stop
                                                    offset="1"
                                                    stop-color="#6C1CD1"
                                                />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </a>
                                <a
                                    v-if="venda.personalizacao.url_facebook"
                                    :href="venda.personalizacao.url_facebook"
                                    target="_blank"
                                >
                                    <svg
                                        width="34"
                                        height="33"
                                        viewBox="0 0 34 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.9671 32.8397C25.9084 32.8397 33.1568 25.5419 33.1568 16.5395C33.1568 7.53712 25.9084 0.239258 16.9671 0.239258C8.02573 0.239258 0.777344 7.53712 0.777344 16.5395C0.777344 25.5419 8.02573 32.8397 16.9671 32.8397Z"
                                            fill="#3B5998"
                                        />
                                        <path
                                            d="M21.0349 17.1772H18.146V27.8329H13.7692V17.1772H11.6875V13.4324H13.7692V11.009C13.7692 9.27611 14.5867 6.5625 18.185 6.5625L21.4271 6.57616V10.2112H19.0747C18.6889 10.2112 18.1463 10.4052 18.1463 11.2319V13.4359H21.4173L21.0349 17.1772Z"
                                            fill="white"
                                        />
                                    </svg>
                                </a>
                                <a
                                    v-if="venda.personalizacao.url_empresa"
                                    :href="venda.personalizacao.url_empresa"
                                    target="_blank"
                                >
                                    <svg
                                        width="33"
                                        height="33"
                                        viewBox="0 0 33 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13.604 8.38672C11.6528 9.17019 10.0446 10.6309 9.07031 12.5047H11.8758C12.1752 11.0303 12.7627 9.63055 13.604 8.38672Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M11.4404 16.5708C11.4409 15.6635 11.5053 14.7575 11.6329 13.8594H8.50229C7.93153 15.6213 7.93153 17.5203 8.50229 19.2822H11.6329C11.5053 18.3841 11.4409 17.478 11.4404 16.5708Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M20.3944 12.5047C19.6693 9.56422 18.2137 7.75977 16.8261 7.75977C15.4385 7.75977 13.9802 9.56422 13.2578 12.5047H20.3944Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M12.7852 16.5708C12.7834 17.4783 12.8496 18.3847 12.9831 19.2822H20.6664C20.9303 17.4844 20.9303 15.6572 20.6664 13.8594H12.9831C12.8496 14.7569 12.7834 15.6633 12.7852 16.5708Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M9.07031 20.6387C10.0446 22.5124 11.6528 23.9732 13.604 24.7566C12.7627 23.5128 12.1752 22.113 11.8758 20.6387H9.07031Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M22.2121 16.5708C22.2115 17.478 22.1472 18.3841 22.0195 19.2822H25.1502C25.721 17.5203 25.721 15.6213 25.1502 13.8594H22.0195C22.1472 14.7575 22.2115 15.6635 22.2121 16.5708Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M16.8262 0.302734C13.6304 0.302734 10.5064 1.25687 7.84917 3.04447C5.19196 4.83208 3.12092 7.37288 1.89795 10.3456C0.67497 13.3182 0.354983 16.5893 0.978452 19.7451C1.60192 22.9009 3.14084 25.7996 5.40061 28.0748C7.66038 30.35 10.5395 31.8994 13.6739 32.5272C16.8083 33.1549 20.0572 32.8327 23.0097 31.6014C25.9622 30.3701 28.4858 28.2849 30.2613 25.6096C32.0368 22.9342 32.9844 19.7889 32.9844 16.5713C32.9794 12.2581 31.2755 8.12307 28.2463 5.07322C25.2171 2.02337 21.1101 0.307758 16.8262 0.302734ZM16.8262 26.7391C14.8288 26.7391 12.8763 26.1427 11.2156 25.0255C9.5548 23.9082 8.2604 22.3202 7.49604 20.4623C6.73168 18.6044 6.53169 16.56 6.92136 14.5876C7.31102 12.6152 8.27285 10.8035 9.68521 9.38151C11.0976 7.95952 12.897 6.99113 14.856 6.5988C16.815 6.20647 18.8455 6.40783 20.6909 7.17741C22.5362 7.94699 24.1134 9.25022 25.2231 10.9223C26.3328 12.5944 26.9251 14.5602 26.9251 16.5713C26.9221 19.267 25.8571 21.8514 23.9638 23.7576C22.0706 25.6638 19.5037 26.736 16.8262 26.7391Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M13.2578 20.6387C13.9829 23.5792 15.4385 25.3837 16.8261 25.3837C18.2137 25.3837 19.672 23.5792 20.3944 20.6387H13.2578Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M20.0469 8.38672C20.8882 9.63055 21.4757 11.0303 21.7751 12.5047H24.5806C23.6063 10.6309 21.9981 9.17019 20.0469 8.38672Z"
                                            fill="#F9A032"
                                        />
                                        <path
                                            d="M20.0469 24.7566C21.9981 23.9732 23.6063 22.5124 24.5806 20.6387H21.7751C21.4757 22.113 20.8882 23.5128 20.0469 24.7566Z"
                                            fill="#F9A032"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.title-col {
    font-size: 25px;
    color: #14d200;
    padding-inline: 5px;
    background-color: #01ae4221;
    border-radius: 5px;
    max-inline-size: fit-content;
}
.item-list {
    margin: 0;
}

.btn-chat,
.btn-whats {
    padding: 8px 20px;
    border-radius: 10px;
    width: 150px;
    text-decoration: none;
    color: white;
}

.btn-chat {
    background-color: #00b3f5;
}

.btn-whats {
    background-color: #14d200;
}
</style>
