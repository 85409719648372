import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

export const WebSocket = {
    iniciarLaravelEcho() {
        if (window.wsConected) {
            return
        }

        window.Pusher = Pusher
        window.wsConected = true
        window.Echo = new Echo({
            broadcaster: 'reverb',
            key: process.env.VUE_APP_WS_KEY,
            wsHost: process.env.VUE_APP_WS_URL,
            wsPort: process.env.VUE_APP_WS_PORT,
            wssPort: process.env.VUE_APP_WS_PORT,
            forceTLS: false,
            enabledTransports: ['ws', 'wss'],
        })
    },
}
