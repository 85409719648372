<template>
    <v-row class="d-flex justify-center align-centerce" style="height: 100%">
        <v-col cols="12" class="d-flex justify-center align-center">
            <a :href="getLinkBoleto" target="_blank">
                <v-btn class="mt-5" color="blue" dark>Abrir boleto</v-btn>
            </a>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'FormularioBoleto',
    props: {
        venda_id: Number,
    },
    computed: {
        getLinkBoleto() {
            var route = process.env.VUE_APP_API_URL.replace('api/', '')
            return route + 'boleto/' + btoa(this.venda_id * 2.5)
        },
    },
}
</script>

<style></style>
