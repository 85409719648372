import { http, http2 } from './config'

export const services = {
    // PAGAMENTO/COBRANCAS
    gerarChavePix: (form) => {
        var uri = '/cobranca/gerar/pix'
        return http.post(uri, form)
    },

    pagarCartao(form) {
        const uri = '/pagamento/cartao'
        return http.post(uri, form, { validateStatus: () => true })
    },

    // AGENDA VIDEOCONFERENCIA
    listarHorarios(form) {
        var uri = '/videoconferencia/horarios-disponiveis'
        return http.post(uri, form)
    },

    agendarHorario(form) {
        const uri = '/videoconferencia/agendar'
        return http2.post(uri, form)
    },

    reagendar(form) {
        const uri = '/videoconferencia/reagendar'
        return http2.post(uri, form)
    },

    //VENDA RAPIDA
    getPaginaVendaRapida(token) {
        var uri = '/venda-rapida/token/' + token
        return http.get(uri)
    },

    createNovaVenda(form, headers) {
        return http.post('/venda-rapida/nova-venda', form, {
            validateStatus: () => true,
            headers: headers,
        })
    },

    importarSoluti(form) {
        var uri = '/integracao/soluti/videoconferencia/importar'
        return http.post(uri, form)
    },

    novaVendaSoluti(form) {
        var uri = '/integracao/soluti/videoconferencia/nova-solicitacao'
        return http.post(uri, form)
    },

    getVendaDocumentacaoPendente(id) {
        return http2.get(`/documentacao-pendente/${id}`)
    },

    storeDocumentacaoPendente(form) {
        return http2.post('/documentacao-pendente', form)
    },
}
