<template>
    <div>
        <v-row>
            <v-col cols="12" class="titulo-pagamento">
                <span>Agendamento</span>
            </v-col>
        </v-row>

        <v-row style="min-height: 460px">
            <v-col cols="12" md="6" class="conteudo pl-4 pr-6">
                <v-col cols="12">
                    <label>Selecione a data do Agendamento</label>
                    <v-menu
                        :close-on-content-click="true"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="data_formatada"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                                solo
                                align="center"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="form.data"
                            color="cor-sistema lighten-2"
                            no-title
                            scrollable
                            locale="pt-br"
                            solo
                            @input="listarHorarios()"
                            :min="new Date().toISOString()"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-col>

            <v-col>
                <v-row v-if="horarios_disponiveis.length > 0" justify="center">
                    <v-col cols="12">
                        <label>Selecione um Horário</label>

                        <div class="d-flex justify-center align-center">
                            <v-chip-group
                                column
                                v-model="form.hora"
                                color="#1FC835"
                                class="mt-n2 d-flex"
                            >
                                <v-chip
                                    class="d-flex justify-center align-center"
                                    solo
                                    style="width: 70px"
                                    v-for="horario in horarios_disponiveis"
                                    :key="horario"
                                    :value="horario"
                                    outlined
                                    label
                                    v-html="horario.slice(11)"
                                    @click="form.hora = horario"
                                ></v-chip>
                            </v-chip-group>
                        </div>

                        <p class="mt-5 d-flex justify-center align-center">
                            <v-icon>mdi-clock-time-four-outline</v-icon>
                            Horário de Brasília - DF
                        </p>
                    </v-col>
                    <v-col
                        cols="12"
                        class="mt-10 d-flex justify-center align-center"
                    >
                        <v-btn
                            width="80%"
                            color="#1FC835"
                            v-if="!reagendamento"
                            :dark="!!form.hora"
                            :disabled="!form.hora"
                            @click="salvarHorario"
                            >Agendar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2" class="d-flex justify-start">
                <v-btn color="blue" dark @click="voltarPasso"> Voltar </v-btn>
            </v-col>
            <v-col cols="2" offset="8" class="d-flex justify-end">
                <v-btn
                    color="green"
                    @click="proximoPasso"
                    :disabled="liberaBotaoProximo"
                    :dark="!!data_hora_agendada"
                >
                    Próximo
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { services } from '@/services/PaginasExternasServices'
import { ResponseHelper } from '@/helpers/ResponseHelper'

export default {
    name: 'Agendamento',
    mixins: { services },
    props: {
        venda_id: Number,
        data_hora_agendada: [String, null],
        reagendar: Boolean,
    },
    data: () => ({
        data_formatada: null,
        reagendamento: false,
        horarios_disponiveis: [],
        form: {
            data: null,
            hora: null,
        },
    }),
    computed: {
        liberaBotaoProximo() {
            return [null, undefined].includes(this.data_hora_agendada)
        },
    },
    methods: {
        proximoPasso() {
            this.$emit('proximoPasso')
        },
        voltarPasso() {
            this.$emit('voltarPasso')
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        async salvarHorario() {
            this.$store.commit('setStatus', true)

            const form = {
                venda_id: this.venda_id,
                data_hora: this.form.hora,
            }

            const response = this.reagendar
                ? await services.reagendar(form)
                : await services.agendarHorario(form)

            this.$store.commit('setStatus', false)

            if (response.status !== 200) {
                const mensagem =
                    ResponseHelper.mensagemErro(response.data) ||
                    'Erro ao agendar pedido'
                this.$notify({ type: 'error', text: mensagem })
                return
            }
          window.dataLayer?.push({'event': 'loja_agendamento_enviado', 'loja_agendamento_enviado': response.data});
            this.$emit('proximoPasso')
            this.$emit(
                'agendamentoEfetuado',
                response.data.returned.dataHoraAgendada
            )
        },

        async listarHorarios() {
            this.$store.commit('setStatus', true)
            this.data_formatada = this.formatDate(this.form.data)
            this.form.hora = null

            var form = {
                venda_id: this.venda_id,
                data: this.form.data,
            }
            services
                .listarHorarios(form)
                .then((resposta) => {
                    if (resposta.status !== 200 && resposta.data.errors) {
                        throw Error(
                            'A Consulta de horários falhou, tente novamente mais tarde'
                        )
                    }
                    this.horarios_disponiveis =
                        resposta.data.returned.horariosDisponiveis
                })
                .catch((error) => {
                    const mensagemError =
                        error.response.data.mensagem ||
                        error.response.data.message ||
                        'Erro ao consultar horários da data: '.this.form.data
                    this.$notify({ type: 'error', text: mensagemError })
                })
                .finally(() => {
                    this.$store.commit('setStatus', false)
                })
        },
    },
}
</script>

<style scoped></style>
